import React from "react";
import { Link } from "react-router-dom";
import YourPrivacyChoices from "../../../assets/footer/privacy-choices-icon.svg";
import styles from "./rds-copyright.module.css";

const YourPrivacyConent = ({ contentData = {} }) => {
  const { privacyChoiceText, alt, width, height, url, target } = contentData;
  return (
    <div className={`${styles.privacyContainer}`}>
      <Link to={url} target={target}>
        {privacyChoiceText}
        <img src={YourPrivacyChoices} alt={alt} width={width} height={height} />
      </Link>
    </div>
  );
};

const CopyRightLinks = ({ contentData = {} }) => {
  const { name, uri } = contentData;
  return (
    <div className={`d-flex gap-2 ${styles.privacyContainer}`}>
      <Link to={uri}>{name}</Link>
      <span className={styles.separator}>{"|"}</span>
    </div>
  );
};

const CopyRight = ({
  copyRightContent = {},
  copyRightLinks = [],
  privacyChoiceContent = {},
}) => {
  return (
    <div
      className={`d-flex flex-wrap align-items-start align-items-sm-end gap-2 ${styles.navItems}`}
    >
      <span>{copyRightContent.copyRight}</span>
      <span className={styles.vr}>{"|"}</span>
      {copyRightLinks?.map((contentData) => (
        <CopyRightLinks key={contentData.name} contentData={contentData} />
      ))}
      <YourPrivacyConent contentData={privacyChoiceContent} />
    </div>
  );
};

export default CopyRight;
