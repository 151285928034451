import React from "react";
import RdsCard from "./rds-card";
import { Markup } from "interweave";

const RdsFeaturedBlogsTray = ({
  heading,
  subHeading,
  blogPosts,
  handleRedirectFromBLP,
  onClickRedirectAction,
}) => {
  const featuredBlog = blogPosts.slice(0, 1);
  const regularBlogs = blogPosts.slice(1);
  const {
    heading: featuredHeading,
    datePublished,
    featuredImage,
    subHeading: featuredSubHeading,
    categories,
    redirectUrl,
  } = featuredBlog[0];

  const handleRedirect = (selectedCategory, redirectURL) => {
    handleRedirectFromBLP?.({ selectedCategory, redirectURL });
  };

  return (
    <div className="container pb-4">
      <div className="py-3">
        <h1 className="fw-bold">{heading}</h1>
        <p className="h5 text-muted fw-bold">
          <Markup content={subHeading} />
        </p>
      </div>

      <div className="row">
        <div className="col-lg-7">
          <div className="mb-3">
            <RdsCard
              variant={"vertical"}
              title={featuredHeading}
              footer={datePublished}
              image={featuredImage}
              subtitle={featuredSubHeading}
              type={"featured"}
              categories={categories.slice(0, 1)}
              handleRedirect={handleRedirect}
              redirectUrl={redirectUrl}
              onClickRedirectAction={onClickRedirectAction}
            />
          </div>
        </div>

        <div className="col-lg-5">
          {regularBlogs.map((blog, index) => {
            return (
              <div className="mb-3" key={index}>
                <RdsCard
                  variant={"horizontal"}
                  title={blog?.heading}
                  footer={blog?.datePublished}
                  image={blog?.featuredImage}
                  subtitle={blog?.subHeading}
                  categories={blog?.categories.slice(0, 1)}
                  handleRedirect={handleRedirect}
                  redirectUrl={blog?.redirectUrl}
                  onClickRedirectAction={onClickRedirectAction}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RdsFeaturedBlogsTray;
