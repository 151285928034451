import React from "react";
import "../stylesheets/rds-external-system-message.css";

const ExternalSystemMessageTray = (message) => {
  const externalFranchiseMessage = message.externalFranchiseMessage;

  return (
    <div className="rds-external-system-message-wrapper">
      <span>{externalFranchiseMessage}</span>
    </div>
  );
};

export default ExternalSystemMessageTray;
