import React from "react";
import HeroBanner from "../../Components/HeroBanner";
import WhatIsAceHardwareServices from "../../sections/WhatIsAceHomeService";
import OurServices from "../../sections/OurServices";
import HowItWorks from "../../sections/HowItWorks";
import { HomeBannerData, HomeContentData } from "../../constants/home";
import { OurServicesData } from "../../constants/services";
import { HowItWorksData } from "../../constants/howItWorks";
import { useIsMobile } from "../../Utilities/Utility";

const HomePage = () => {
  const isMobileDevice = useIsMobile();

  return (
    <>
      <HeroBanner
        contentData={HomeBannerData}
        isMobileDevice={isMobileDevice}
      />
      <OurServices contentData={OurServicesData} />
      <WhatIsAceHardwareServices contentData={HomeContentData} />
      <HowItWorks contentData={HowItWorksData} />
    </>
  );
};

export default HomePage;
