import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const CanonicalTagWrapper = ({ children }) => {
  let location = useLocation();
  const baseURL = "https://www.acehardwarehomeservices.com";

  useEffect(() => {
    let canonicalURL = correctTrailingSlash(location.pathname);
    setCanonicalTag(canonicalURL);
  }, [location]);

  const correctTrailingSlash = (URL) => {
    let slashCorrectedURL = URL;
    /* removing ending slash to avoid duplicate URLS */
    if (slashCorrectedURL.endsWith("/")) {
      slashCorrectedURL = slashCorrectedURL.substr(
        0,
        slashCorrectedURL.length - 1
      );
    }

    return slashCorrectedURL;
  };

  const setCanonicalTag = (canonicalURL) => {
    //Meta tag implementation
    const canonicalTag = document.querySelector("[rel=canonical]");
    if (canonicalTag) {
      // If canonical Tag present update
      canonicalTag.href = `${baseURL}${canonicalURL}`;
    } else {
      // If canonical Tag not present create
      const node = document.createElement("link");
      node.setAttribute("rel", "canonical");
      node.setAttribute("href", `${baseURL}${canonicalURL}`);
      const viewportMeta = document.querySelector("[name=viewport]");
      viewportMeta.before(node);
    }
  };

  return children;
};

export default CanonicalTagWrapper;
