import React from "react";
import styles from "../stylesheets/rds-pagination.module.css";
import "../stylesheets/rds-bootstrap.css";
import RightArrow from "../assets/RightArrow.png";
import LeftArrow from "../assets/LeftArrow.png";

const RdsPagination = ({ currentPage, totalPages, onPageChange }) => {
  const getPageNumber = (currentPage, totalPages) => {
    if (totalPages <= 6) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }
    if (currentPage < 4) {
      return [1, 2, 3, 4, "...", totalPages];
    }

    if (currentPage >= totalPages - 5) {
      return [
        totalPages - 5,
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ];
    }
    return [
      currentPage,
      currentPage + 1,
      currentPage + 2,
      currentPage + 3,
      "...",
      totalPages,
    ];
  };
  const pageNumbers = getPageNumber(currentPage, totalPages);
  return (
    <div className={styles.customBtn}>
      {totalPages > 6 && currentPage !== 1 && (
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <img src={LeftArrow} />
        </button>
      )}
      {pageNumbers.map((number, index) => {
        return (
          <button
            key={index}
            onClick={() => onPageChange(number)}
            className={currentPage === number ? styles.active : ""}
            disabled={number === "..."}
          >
            {number}
          </button>
        );
      })}
      {totalPages > 6 && !(currentPage >= totalPages - 5) && (
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <img src={RightArrow} />
        </button>
      )}
    </div>
  );
};

export default RdsPagination;
