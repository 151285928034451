import React from "react";
import HVAC from "./HVAC";
import Electrical from "./Electrical";
import Painting from "./Painting";
import Plumbing from "./Plumbing";
import Handyman from "./Handyman";

export const iconMap = {
  HVAC: HVAC,
  PLUMBING: Plumbing,
  ELECTRICAL: Electrical,
  PAINTING: Painting,
  HANDYMAN: Handyman,
};

const RenderServiceIcon = (iconName, iconProperties) => {
  const IconComponent = iconMap[iconName?.toUpperCase()];
  return IconComponent ? <IconComponent {...iconProperties} /> : "";
};

export default RenderServiceIcon;
