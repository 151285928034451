import React, { useEffect, useState } from "react";
import { useSpring, animated } from "@react-spring/web";

import MenuItems from "../MenuItems";
import styles from "./dropdown.module.css";

const DropDown = (
  {
    subMenuItems = [],
    dropdown = false,
    depthLevel = 0,
    position = {},
    parentMenuName = "",
    ...rest
  }
) => {
  const {
    leftPos,
    topPos,
    height,
    parentTop
  } = position;

  depthLevel = depthLevel + 1;
  const [borderRds, setBorderRds] = useState({});

  useEffect(() => {
    const leftPosVal = parseInt(leftPos, 10);

    if (depthLevel === 2) {
      const brStyles = {
        borderBottomRightRadius: leftPosVal < 0 ? 0 : "",
        borderTopRightRadius: leftPosVal < 0 ? 0 : "",
        borderBottomLeftRadius: leftPosVal > 0 ? 0 : "",
        borderTopLeftRadius: leftPosVal > 0 ? 0 : "",
      }
      setBorderRds(brStyles)
    }

  }, [leftPos, depthLevel])

  //calculating the Header height and minus from the parentTop
  //value plus minro pixel adjustment helps to calculate the 
  //top position of the L2 menu item.
  const headerEl = document.getElementsByTagName("header")[0];
  const headerElHeight = headerEl?.getBoundingClientRect().height;
  const childMenuTopPos = Math.round(parentTop - headerElHeight + 5);

  const animation = useSpring({
    opacity: dropdown ? 1 : 0,
  });

  return (
    /* On L2 menu, if the menu depth is 2 then changing the top value to -(calculated top),
    border radius for top/left, bottom/left
    */
    <animated.div
      className={`${styles.dropDownContainer} ${dropdown ? styles.show : ""}`}
      style={{
        top: depthLevel === 2 ? -(childMenuTopPos) : topPos,
        left: leftPos,
        minHeight: height,
        ...borderRds,
        ...animation,
      }}
    >
      <ul className={styles.dropDownList}>
        {depthLevel > 1 && <li className={styles.dropDownName}>{parentMenuName}</li>}
        {subMenuItems?.map((subMenu, index) => {
          return (
            <MenuItems
              {...{ items: subMenu, depthLevel, ...rest }}
              key={index} />
          );
        })}
      </ul>
    </animated.div>
  );
};

export default React.memo(DropDown);
