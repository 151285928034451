import React, { useEffect, useState } from "react";
import styles from "./privacyPolicy.module.css";
import { useIsMobile } from "../../Utilities/Utility";
import DynamicTrays from "../../Components/DynamicTrays";
import { fetchPrivacyPolicy } from "../../redux-toolkit/slice/PrivacyPolicy/PrivacyPolicySlice";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import ErrorPage from "../../Components/ErrorPage";
import useMetaDetails from "../../hooks/useMetaDetails";

const PrivacyPolicy = () => {
  const isMobile = useIsMobile();

  const { lobType } = useParams();
  const {
    data = {},
    isError,
    statusCode,
  } = useSelector((state) => state.PrivacyPolicy);
  const dispatch = useDispatch();
  const [isPageContainsError, setIsPageContainsError] = useState(false);

  useMetaDetails(data);

  useEffect(() => {
    const payLoad = `?lobtype=${lobType}/service-locations`;
    dispatch(fetchPrivacyPolicy(payLoad));
  }, [lobType]);

  useEffect(() => {
    setIsPageContainsError(isError);
  }, [isError, statusCode]);

  return (
    <>
      <div
        className={` container ${
          !isMobile ? styles.privacyPolicyContainer : ""
        }`}
      >
        <div className="row">
          <div className="col-12">
            <div className={`${styles.privacyTitle}`}>
              <h1>{data?.pageHeading}</h1>
            </div>
          </div>
        </div>
      </div>
      {data?.trays?.map((tray, index) => {
        return <DynamicTrays {...tray} key={index} />;
      })}
      {isPageContainsError && <ErrorPage statusCode={statusCode} />}
    </>
  );
};
export default PrivacyPolicy;
