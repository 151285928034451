import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "../stylesheets/SquareCard.module.css";

const RdsSquareCard = (props) => {
  const {
    isClickableCard,
    image,
    title,
    showMoreLink,
    learnMoreLink,
    viewServices,
    children,
    altImageText
  } = props;

  const baseCard = () => (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <img src={image} alt={altImageText? altImageText : title} width="124" height="124" />
      <h3 className={styles.cardTitle}>{title}</h3>
      {children}
    </div>
  );

  const baseCardStyle = `${styles.squareCardContainer} shadow rounded-3 p-3`;

  return (
    <>
      {isClickableCard ? (
        <Link
          to={showMoreLink ? learnMoreLink : viewServices}
          target={showMoreLink ? "_blank" : "_self"}
          style={{ textDecoration: "none", color: "inherit" }}
          className={baseCardStyle}
        >
          {baseCard()}
        </Link>
      ) : (
        <div className={baseCardStyle}>{baseCard()}</div>
      )}
    </>
  );
};

RdsSquareCard.propTypes = {
  isClickableCard: PropTypes.bool,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  showMoreLink: PropTypes.bool,
  learnMoreLink: PropTypes.string,
  viewServices: PropTypes.string,
  linkColor: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
};

export default RdsSquareCard;
