import {
  faFacebook,
  faInstagram,
  faTwitter,
  faPinterest,
  faYoutube,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

export const iconMap = {
  fabfafacebookf: faFacebook,
  fabfainstagram: faInstagram,
  fabfatwitter: faTwitter,
  fabfapinterest: faPinterest,
  fabfayoutube: faYoutube,
  fabfalinkedin: faLinkedin,
};
