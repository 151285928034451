import React, { useEffect, useState, useId } from "react";
import "../stylesheets/rds-bootstrap.css";
import PropTypes from "prop-types";
import "../stylesheets/rds-common.css";
import styles from "../stylesheets/rds-blog-post.module.css";
import { Interweave } from "interweave";

const RdsBlogPost = ({ blogPost, handleRedirectFromBLP, isMobile }) => {
  const uniqueId = useId();

  const {
    heading,
    subHeading,
    featuredImage,
    featuredVideo,
    blogContent,
    datePublished,
    categories,
  } = blogPost;

  const categoriesCount = Number(categories?.length);
  const visibleCategories = isMobile
    ? categories?.slice(0, 1)
    : categories?.slice(0, 3);
  const seeMoreCount = isMobile ? categoriesCount - 1 : categoriesCount - 3;
  const seeMoreCategories = isMobile
    ? categories?.slice(1)
    : categories?.slice(3);
  const validCategoryCount = isMobile
    ? categoriesCount > 1
      ? true
      : false
    : categoriesCount > 3
    ? true
    : false;

  const handleOnClick = (selectedCategory, redirectURL) => {
    handleRedirectFromBLP?.({ selectedCategory, redirectURL });
  };

  const linkOpenNewTab = (node, children) => {
    if (node.tagName === "A") {
      return (
        <a
          href={node.getAttribute("href")}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    }
  };
  return (
    <div className="container py-4">
      <div className="row">
        <div className="row">
          <div className="col d-flex flex-wrap m-0 mb-2">
            {visibleCategories.map((category) => (
              <span
                key={category.categoryName}
                className={`${styles.category} rds-font-label`}
                onClick={() =>
                  handleOnClick(category?.categoryName, category?.redirectUrl)
                }
              >
                {category.categoryName}
              </span>
            ))}
            {validCategoryCount && (
              <input
                type="checkbox"
                id={uniqueId}
                className={styles.checkbox}
              />
            )}
            {validCategoryCount && (
              <label
                htmlFor={uniqueId}
                className={`${styles.more} rds-font-label`}
              >
                {`+ ${Number(seeMoreCount)} MORE`}{" "}
              </label>
            )}
            {seeMoreCategories.map((category) => (
              <span
                key={category.categoryName}
                className={`${styles.category} ${styles.extras} rds-font-label`}
                onClick={() =>
                  handleOnClick(category?.categoryName, category?.redirectUrl)
                }
              >
                {category.categoryName}
              </span>
            ))}
          </div>
          <h1 className={`${styles.heading} rds-font-title-3 m-0 mb-2`}>
            {heading}
          </h1>
          {subHeading && (
            <p className={`${styles.subHeading} rds-font-title-5 m-0 mb-2`}>
              {subHeading}
            </p>
          )}
          <span className={`${styles.footNote} rds-font-product-title-2 m-0 mb-3`}>
            {datePublished}
          </span>
        </div>
        <div className="pb-4">
          {featuredVideo ? (
            <video src={featuredVideo} controls width="100%" />
          ) : featuredImage ? (
            <img
              className={`${styles.featureImage}`}
              src={featuredImage}
              width="2"
              height="1"
            />
          ) : (
            ""
          )}
        </div>
        <Interweave
          content={blogContent}
          transform={linkOpenNewTab}
          className="pt-3"
        />
      </div>
    </div>
  );
};

RdsBlogPost.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  featuredVideo: PropTypes.string,
  featuredImage: PropTypes.string,
  blogContent: PropTypes.string,
  datePublished: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      categoryName: PropTypes.string,
      redirectUrl: PropTypes.string,
    })
  ),
  handleRedirectFromBLP: PropTypes.func,
};

export default RdsBlogPost;
