import React from "react";
import { Link } from "react-router-dom";
import styles from "./rds-socialmedia.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { iconMap } from "../footerData";

const SocialMedia = ({ socialMediaItems = {} }) => {
  const { socialMediaText = "", socialMediaLinks = [] } = socialMediaItems;
  return (
    <div className={`${styles.socialMediaContainer}`}>
      <h4 className={`m-0 ${styles.heading}`}>{socialMediaText}</h4>
      <ul className="d-flex justify-content-end justify-content-sm-start">
        {socialMediaLinks.map((item, index) => {
          return (
            <li key={index}>
              <Link
                to={item.uri}
                target={item?.openInNewTab ? "_blank" : "_self"}
                rel={item?.openInNewTab ? "noopener noreferrer" : ""}
              >
                <FontAwesomeIcon
                  icon={iconMap[item.icon.split(/[\s-]+/).join("")]}
                />
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SocialMedia;
