import React from "react";
import "../stylesheets/rds-bootstrap.css";
import "../stylesheets/rds-review-carousel.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Markup } from "interweave";
import Rating from "react-rating";
import starEmpty from "../assets/star-empty.png";
import starRed from "../assets/star-red.png";

const isMobile = window.screen.width < 768;

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: isMobile ? 1 : 3,
  slidesToScroll: 1,
};

class RdsReviewCarousel extends React.Component {
  onClickHandler = (e) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  render() {
    const {
      sectionHeading,
      heading,
      anchorTagValue,
      franchiseReviews,
      franchiseViewAllReviewsLink,
    } = this.props;

    return (
      <>
        <div
          className={
            isMobile
              ? "rds-wrapper rds-review-carousel-background mobile"
              : "rds-wrapper rds-review-carousel-background"
          }
          id={anchorTagValue}
        >
          <div className="container">
            <div className="row justify-content-center pt-4">
              <h2 className="rds-review-carousel-heading rds-section-heading">{sectionHeading}</h2>
              <h3 className="rds-review-carousel-subHeading rds-heading mb-4">{heading}</h3>
            </div>

            <Slider {...settings}>
              {franchiseReviews &&
                franchiseReviews.length > 0 &&
                franchiseReviews.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="m-2 mb-5 review-container slider"
                    >
                      <div className="review-count">
                        <div className="d-flex align-items-center">
                          <div className="review-count-label">
                            {data.rating}
                          </div>
                          <div className="d-flex align-items-center rating">
                            <Rating
                              placeholderRating={data.rating}
                              emptySymbol={
                                <img
                                  alt="Empty Rating"
                                  src={starEmpty}
                                  className="rds-rating-icon-reviews"
                                />
                              }
                              placeholderSymbol={
                                <img
                                  alt="Star Rating"
                                  src={starRed}
                                  className="rds-rating-icon-reviews"
                                />
                              }
                              readonly={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="review-title my-2">{data.authorName}</div>
                      {data?.reviewDescription && (
                        <div className="review-message">
                          <Markup content={data.reviewDescription} />
                        </div>
                      )}
                      {data.reviewDescription &&
                        data.reviewDescription.length > 300 && (
                          <a
                            href={franchiseViewAllReviewsLink}
                            target="_blank"
                            className="rds-review-readm-more"
                            rel="noreferrer"
                          >
                            Read more
                          </a>
                        )}
                    </div>
                  );
                })}
            </Slider>

            <div className="d-flex justify-content-center py-4">
              <a
                href={franchiseViewAllReviewsLink}
                target="_blank"
                className="rds-review-see-all"
                rel="noreferrer"
              >
                SEE ALL REVIEWS{" "}
                <i className="fa-icon trailing fa-regular fa-arrow-right ml-2"></i>
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RdsReviewCarousel;
