import React, { useState, useEffect } from "react";
import { useTransition, animated } from "@react-spring/web";

import MenuList from "../MenuList";
import styles from "./submenu.module.css";

const SubMenu = (props) => {
  const {
    data = {},
    handleMenuToggle = {},
    handleNextArrow = {},
    logoPanelRef = {},
    ctaPanelRef = {},
  } = props;
  const { menuItems, menuTitle } = data;

  const [menuHeight, setMenuHeight] = useState(0);

  useEffect(() => {
    const ctaPanelHeight = ctaPanelRef?.current ? ctaPanelRef?.current?.offsetHeight : 0;
    if (logoPanelRef?.current) {
      const logoPanelHeight = logoPanelRef?.current?.offsetHeight;
      const viewportHeight = window.innerHeight;

      // Calculate height of the Menu content area excluding the logo panel and the footer CTA
      const availableHeight = viewportHeight - logoPanelHeight - ctaPanelHeight;
      setMenuHeight(availableHeight)
    }
  }, [data])

  const transitions = useTransition(data, {
    from: { right: "-200%" },
    enter: { right: "-100%" },
    leave: { right: "-100%" },
  });

  return transitions((style) => (
    <animated.div className={styles.subMenuContainer} style={{ ...style, height: `${menuHeight}px` }}>
      {menuTitle && <h2 className={styles.menuSectionHeading}>{menuTitle}</h2>}
      <ul className={styles.subMenuList}>
        {menuItems?.map((menu, index) => {
          return (
            <MenuList
              data={menu}
              hasSubMenu={menu?.headerSubMenu}
              handleMenuToggle={handleMenuToggle}
              handleNextArrow={() => handleNextArrow(menu.menuText)}
              key={index}
            />
          );
        })}
      </ul>
    </animated.div>
  ));
};

export default SubMenu;
