import React from "react";

import MenuItems from "../MenuItems";
import styles from "./navbar.module.css";

const NavBar = (props) => {
    const {
        menuItems = [],
        isPrimaryNav = false,
        isLastVisitedFranchiseRibbonActive = false,
        secNav = false,
        handleOverLayEnter = {},
        handleOverLayLeave = {},
        ...rest
    } = props;
    const depthLevel = 0;

    return (
        <nav className={`${styles.navMenuContainer} ${!isPrimaryNav ? styles.secondaryNavBg : ""}`} onMouseEnter={handleOverLayEnter} onMouseLeave={handleOverLayLeave} >
            <ul className={`${styles.navMenuItems} ${isPrimaryNav && isLastVisitedFranchiseRibbonActive ? 'me-4' : ""}`}>
                {menuItems && menuItems?.map((menu, index) => {
                    return (
                        <MenuItems
                            {...{ items: menu, depthLevel, secNav, index, ...rest }}
                            key={index}
                        />
                    );
                })}
            </ul>
        </nav>
    );
};

export default NavBar;
