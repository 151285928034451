import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import NetworkManager from "../../networkManager/networkConfig";

export const fetchCorporateBlogList = createAsyncThunk(
  "corporateBlogList/fetchData",
  async (body) => await NetworkManager.corporateBlogList(body)
);

const initialState = {
  listData: null,
  isLoadingList: null,
  isErrorList: null,
  statusCodeList: 0,
};

const corporateBlogListSlice = createSlice({
  name: "CorporateBlogList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCorporateBlogList.pending, (state) => {
        state.isLoadingList = true;
        state.isErrorList = false;
        state.statusCodeList = 0;
      })
      .addCase(fetchCorporateBlogList.fulfilled, (state, action) => {
        state.listData = action.payload.data;
        state.isLoadingList = false;
        state.isErrorList = action.payload.status == "200" ? false : true;
        state.statusCodeList = action.payload.status;
      })
      .addCase(fetchCorporateBlogList.rejected, (state) => {
        state.isLoadingList = false;
        state.isErrorList = true;
        state.statusCodeList = 0;
      });
  },
});

export default corporateBlogListSlice.reducer;
