import React from "react";

const HVAC = ({ fillColor = "#000", strokeColor = "#000" }) => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3248 7.93957C18.3248 5.29429 16.1804 3.1499 13.5352 3.1499V12.7292C16.1804 12.7292 18.3248 10.5848 18.3248 7.93957Z"
        fill={fillColor}
      />
      <path
        d="M8.74609 19.0601C8.74609 21.7054 10.8905 23.8498 13.5358 23.8498V14.2705C10.8905 14.2705 8.74609 16.4149 8.74609 19.0601Z"
        fill={fillColor}
      />
      <path
        d="M19.0943 18.2897C21.7395 18.2897 23.884 16.1453 23.884 13.5H14.3047C14.3047 16.1452 16.4491 18.2897 19.0943 18.2897Z"
        fill={fillColor}
      />
      <path
        d="M7.96935 8.7002C5.32412 8.7002 3.17969 10.8446 3.17969 13.4899H12.759C12.759 10.8446 10.6146 8.7002 7.96935 8.7002Z"
        fill={fillColor}
      />
      <path
        opacity="0.19"
        d="M13.5355 14.094C13.8637 14.094 14.1297 13.828 14.1297 13.4999C14.1297 13.1718 13.8637 12.9058 13.5355 12.9058C13.2074 12.9058 12.9414 13.1718 12.9414 13.4999C12.9414 13.828 13.2074 14.094 13.5355 14.094Z"
        stroke={strokeColor}
        stroke-width="0.206667"
        stroke-miterlimit="10"
      />
      <path
        d="M8.02786 18.1403C7.13114 17.0951 6.52783 15.787 6.35156 14.3398"
        stroke={strokeColor}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.1676 18.9321C17.1224 19.8288 15.8143 20.4322 14.3672 20.6084"
        stroke={strokeColor}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.9609 8.79395C19.8577 9.83912 20.461 11.1472 20.6372 12.5944"
        stroke={strokeColor}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.82031 8.00198C9.86548 7.10526 11.1736 6.50195 12.6207 6.32568"
        stroke={strokeColor}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5351 12.9058C13.8632 12.9058 14.1292 13.1718 14.1292 13.4999C14.1292 13.8281 13.8632 14.0941 13.5351 14.0941C13.2069 14.0941 12.9409 13.8281 12.9409 13.4999C12.9409 13.1718 13.2069 12.9058 13.5351 12.9058ZM13.5351 12.0508C12.736 12.0508 12.0859 12.7009 12.0859 13.4999C12.0859 14.299 12.736 14.9491 13.5351 14.9491C14.3341 14.9491 14.9842 14.299 14.9842 13.4999C14.9842 12.7009 14.3341 12.0508 13.5351 12.0508Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default HVAC;
