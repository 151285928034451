import React from "react";
import LinkWithArrow from "../../LinkWithArrow/LinkWithArrow";
import styles from "./OverLayCard.module.css";
import { useIsMobile } from "../../../Utilities/Utility";

const OverLayCard = ({ cardData = {} }) => {
  const isMobileDevice = useIsMobile();

  const {
    title = "",
    subTitle = "",
    description = "",
    linkText = "",
    url = "",
    linkColor = "",
    showArrow = false,
  } = cardData;

  return (
    <div className={`${styles.overlayContainer}`}>
      <div
        className={`${
          isMobileDevice ? styles.overlayContentMobile : styles.overlayContent
        }`}
      >
        <h1 className={styles.title}>{title}</h1>
        {subTitle && (
          <h2 className={styles.subTitle}>
            {subTitle}
          </h2>
        )}
        {description && (
          <p className={`${description ? styles.description : ""}`}>
            {description}
          </p>
        )}

        {/* Hyperlink with arrow component */}
        {linkText && url && (
          <LinkWithArrow
            linkUrl={url}
            linkText={linkText}
            linkColor={linkColor}
            showArrow={showArrow}
          />
        )}
      </div>
    </div>
  );
};

export default OverLayCard;
