import React from "react";
import "../stylesheets/rds-franchise-banner-form.css";
import "../stylesheets/rds-bootstrap.css";
import Rdsbtn from './rds-btn';
import { validateEmail, validatePhoneNumber } from '../utility';
import RdsInputText from './rds-inputtext';

class RdsFranchiseBannerWithFormTray extends React.Component {
    constructor() {
        super();
        this.state = {

        }
    }
    onClickHandler = (e) => {
        if (this.props.onClick) {
            this.props.onClick(e);
        }
    };


    handleSubmitForm = () => {
        if (this.props.onFormSubmission) {

            this.props.onFormSubmission(this.state)
        }

        this.props.formFields?.map((values) => {
            this.validateInput(values.name, this.state[values.name], (values?.controlType?.toLowerCase() === 'textbox' ? 'text' : values?.controlType?.toLowerCase() === 'phone' ? 'tel' : values?.controlType?.toLowerCase()), values.isRequired, values.errorText)
        })
    }

    validateInput(name, value, type, required, errorText) {
        if (type === "email") {
            if (!validateEmail(value)) {
                this.setState({ [`${name}Error`]: errorText })
            }
            else {
                this.setState({ [`${name}Error`]: '' })
            }
        }
        else if (type === "tel" && required) {
            if (!validatePhoneNumber(value)) {
                this.setState({ [`${name}Error`]: errorText })

            }
            else {
                this.setState({ [`${name}Error`]: '' })
            }
        } else if (type === "text" && required) {
            if (!value) {
                this.setState({ [`${name}Error`]: errorText })
            } else {
                this.setState({ [`${name}Error`]: '' })
            }
        }

    }

    render() {
        const {
            imageUrl,
            logoImage,
            formFields,
            heading,
            imageAltText,
            cta
        } = this.props;

        return (
            <>
                <div className="rds-franchise-banner-form-wrapper" style={{ backgroundImage: `url(${imageUrl})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-12 col-xs-12 d-flex align-items-center pt-4 pb-4">
                                <img alt={imageAltText} title={imageAltText} src={logoImage} />
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <div className="rds-franchise-banner-form-tray-right-content p-4">
                                    <div className="rds-franchise-banner-form">
                                        {heading && (
                                            <h3
                                                className={`rds-heading`}
                                            >
                                                {heading}
                                            </h3>
                                        )}
                                        <div className="row">
                                            {formFields?.map((fieldDetails) => {
                                                return (<div className="col-md-12 col-xs-12 mt-3 mb-2">
                                                    <RdsInputText
                                                        key={fieldDetails?.name}
                                                        type={fieldDetails?.controlType?.toLowerCase() === 'textbox' ? 'text' : fieldDetails?.controlType?.toLowerCase() === 'phone' ? 'tel' : fieldDetails?.controlType?.toLowerCase()}
                                                        containerClass="rds rds-input-text"
                                                        id={fieldDetails?.name}
                                                        text={fieldDetails?.name}
                                                        name={fieldDetails?.name}
                                                        required={fieldDetails?.isRequired}
                                                        errorHelperText={fieldDetails?.errorText}
                                                        errorText={this.state[`${fieldDetails?.name}Error`]}
                                                        maxLength={fieldDetails?.MaxLength}
                                                        onChange={(e) => this.setState({ [fieldDetails?.name]: e.target.value, [`${fieldDetails?.name}Error`]: '' })}
                                                    />
                                                </div>)
                                            })}
                                            <div className="rds-franchise-banner-form-button mt-2">
                                                <Rdsbtn text={cta?.text} buttonClass='rds rds-btn primary' onClick={this.handleSubmitForm} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default RdsFranchiseBannerWithFormTray;
