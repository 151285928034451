import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import FloatingIcon from "../../assets/common/footer/back-to-top-button.svg";
import { scrollToTopSmooth } from "../../Utilities/Utility";
import styles from "./Floating.module.css";

const FloatingCircle = () => {
  const location = useLocation();
  const [enableFloatingIcon, setEnableFloatingIcon] = useState(false)

  useEffect(() => {
    let scrollHeight = Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );

    if ((scrollHeight - 300) > window.innerHeight) {
      window.addEventListener('scroll', e => {
        if(window.scrollY > 300){
          setEnableFloatingIcon(true);
        }else{
          setEnableFloatingIcon(false);
        }
      });
    } else {
      setEnableFloatingIcon(false);
    }
    
  }, [location]);

  return (
    enableFloatingIcon && <div
      className={`${styles.floatingCircleContainer} d-flex justify-content-end`}>
      <img onClick={scrollToTopSmooth} src={FloatingIcon} alt={"back-to-top"} width="76" height="73" />
    </div>
  );
};

export default FloatingCircle;
