import { getTrayStyle, formatPhoneNumber } from "../utility";
import RdsBtn from "./rds-btn";
import styles from "../stylesheets/rds-company-details-tray.module.css";

const RdsCompanyDetailsTray = ({ companyDetails, stateRefs }) => {
  const handleOnClick = (openInNewTab, redirectUrl) => {
    if (/^\d+$/.test(redirectUrl)) {
      window.location.href = `tel:${redirectUrl}`;
    } else if (typeof redirectUrl === "string") {
      if (openInNewTab) {
        window.open(redirectUrl, "_blank");
      } else {
        window.location.href = redirectUrl;
      }
    }
  };

  const renderButtonColumn = (service) => (
    <div
      className={`col-12 col-md-3 text-center d-flex flex-column align-items-center align-items-lg-end`}
    >
      <div className={"col-12 col-md-8 mb-3 order-2 order-md-1"}>
        <RdsBtn
          buttonClass="rds rds-btn primary expanded"
          companyDetails={service}
          iconClass="fa-icon leading fa-regular fa-phone"
          iconType="leading"
          tel={service?.ctas[0]?.redirectUrl}
          text={service?.ctas[0]?.text}
        />
      </div>
      <div className={"col-12 col-md-8 order-1 order-md-2 mb-3 mb-md-0"}>
        <RdsBtn
          buttonClass="rds custom-btn rds-btn secondary expanded"
          text={service?.ctas[1]?.text || "Book Online"}
          onClick={() =>
            handleOnClick(
              service?.ctas[1]?.openInNewTab,
              service?.ctas[1]?.redirectUrl
            )
          }
        />
      </div>
    </div>
  );

  const renderCompanyImageColumn = (service) => (
    <div className="col-12 col-md-3 text-center d-flex flex-column align-items-left mb-3 mb-md-0">
      <a
        href={service?.ctas[2]?.redirectUrl}
        rel="noreferrer"
        target={service?.openInNewTab ? "_blank" : "_self"}
        title={`Visit: ${service?.ctas[2]?.redirectUrl}`}
      >
        <img
          src={service?.companyLogo}
          alt={service?.companyName}
          className={`img-fluid ${styles.CompanyDetailsCompanyLogo}`}
        />
      </a>
    </div>
  );

  const renderInfoColumn = (service) => (
    <div className={`col-12 col-md-6 d-flex flex-column mb-3 mb-md-0`}>
      <div
        className={`mb-3 d-none d-md-block rds-font-product-title-1 ${styles.city}`}
      >
        {`${service?.city}, ${service?.stateAbbreviation}`}
      </div>
      <div className={`mb-3 rds-font-title-5 ${styles.businessName}`}>
        <a
          className={`${styles.companyNameUrl}`}
          href={service?.ctas[2]?.redirectUrl}
          rel="noreferrer"
          target={service?.openInNewTab ? "_blank" : "_self"}
          title={`Visit: ${service?.ctas[2]?.redirectUrl}`}
        >
          {service?.companyName}
        </a>
      </div>
      <div className={`mb-3 rds-font-title-5 ${styles.companyTelephoneUrl}`}>
        <a
          className={`${styles.companyTelephoneUrl}`}
          href={`tel:+${formatPhoneNumber(service?.phoneNumber)}`}
          title={`Call us at: ${formatPhoneNumber(service?.phoneNumber)}`}
        >
          {formatPhoneNumber(service?.phoneNumber)}
        </a>
      </div>
    </div>
  );

  const renderStateNameLabel = (service, index) =>
    index === 0 || service?.state !== companyDetails[index - 1]?.state ? (
      <div
        className={`mt-5 mb-3 rds-font-product-title-1 ${styles?.serviceStateLabel}`}
        id={service?.state}
        ref={(el) => (stateRefs.current[service?.state] = el)} // Set the ref for each state
      >
        {service?.state}
      </div>
    ) : null;

  return (
    <div
      className={`pt-1 pt-md-3 px-3 px-md-0 ${styles?.companyDetailsContainer}`}
    >
      <div className={`container mt-4 `}>
        {companyDetails?.map((service, index) => (
          <div key={index}>
            {renderStateNameLabel(service, index)}
            <div
              className={`row align-items-center border rounded p-3 mb-3`}
              style={getTrayStyle(service?.backgroundColor)}
            >
              <div
                className={`mb-3 d-block d-md-none rds-font-product-title-2`}
              >
                {`${service?.city}, ${service?.stateAbbreviation}`}
              </div>
              {renderCompanyImageColumn(service, index)}
              {renderInfoColumn(service, index)}
              {renderButtonColumn(service, index)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RdsCompanyDetailsTray;
