import React from "react";
import RdsSquare from "../../../AceHomeServicesRds/src/components/rds-squarecard";
import LinkWithArrow from "../../../Components/LinkWithArrow/LinkWithArrow";
import styles from "./ServiceTypePane.module.css";
import { useIsMobile } from "../../../Utilities/Utility";

const ListOfServices = ({ contentData = [] }) => {
  const isMobileDevice = useIsMobile();
  return (
    <div className={`${styles.serviceListContainer} mb-5`}>
      <div className={`row ${styles.gap} ${styles.justifyOnMob}`}>
        {contentData.map((item, index) => {
          return (
            <div
              className={`col-sm-6 col-md-6 col-lg-2 mb-4 ${styles.displayStyle} ${styles.colxs6}`}
              key={index}
            >
              <RdsSquare
                image={isMobileDevice ? item.imageMobile : item.image}
                title={item.serviceArea}
                showMoreLink={item.displayLearnMoreLink}
                learnMoreLink={item.learnMore}
                viewServices={item.ViewServices}
                isClickableCard={item.isClickableCard}
              >
                <LinkWithArrow
                  linkUrl={
                    item.displayLearnMoreLink
                      ? item.learnMore
                      : item.ViewServices
                  }
                  isIndividualLink={item.isClickableCard}
                />
              </RdsSquare>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ListOfServices;
