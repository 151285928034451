import React from "react";

const Tip = () => {
  return (
    <svg
      width="16"
      height="8"
      viewBox="0 0 16 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Rectangle 235"
        d="M7.29289 0.707106C7.68342 0.316582 8.31658 0.316582 8.70711 0.707107L16 8H0L7.29289 0.707106Z"
        fill="#515151"
      />
    </svg>
  );
};

export default Tip;
