import React from "react";

const FaChevronDown = ({ fillColor = "#000", rotate = 0 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 512 512">
      {/* <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--> */}
      <path
        d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
        fill={fillColor}
        style={{ transition: "all 300ms ease", transformOrigin: 'center', transform: `rotate(${rotate}deg)` }}
      />
    </svg>
  );
};

export default FaChevronDown;
