import React from "react";
import { Link } from "react-router-dom";
import "../stylesheets/rds-bootstrap.css";
import "../stylesheets/rds-common.css";
import styles from "../stylesheets/rds-service-areas-tray.module.css";

const RdsServiceAreasTray = (props) => {
  const {
    heading,
    imageAlignment,
    imageUrl,
    sectionHeading,
    serviceAreaDetails = [],
    subHeading,
    renderAction,
    imageAltText,
    variant = "Default",
    copyAboveLocationsListing,
    copyBelowLocationsListing,
    handleScrollTo,
    anchorTagValue = "",
  } = props;

  const columnAlignment = imageAlignment == "Left" ? "order-first" : "";

  const renderFeature = () => {
    if (variant === "Overlay") {
      return (
        <div
          className={`${styles.backgroundImage} h-100`}
          style={{
            backgroundImage: `url(${imageUrl})`,
          }}
        >
          <div className={`${styles.backgroundOverlay} align-content-center`}>
            <div className={`rds-font-title-1 ps-5 pe-5 pt-4`}>{heading}</div>
            <div className={`rds-font-body-1 ps-5 pe-5 pt-1 pb-4`}>
              {subHeading}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <img
          src={imageUrl}
          alt={imageAltText ? imageAltText : sectionHeading}
          title={imageAltText ? imageAltText : sectionHeading}
          className={styles.image}
        />
      );
    }
  };

  const renderServiceAreaContent = () => {
    return (
      <>
        {sectionHeading && (
          <h2
            className={`${styles.sectionHeading} rds-font-label pt-3 pt-md-0`}
          >
            {sectionHeading}
          </h2>
        )}
        {heading && (
          <h3 className={`${heading} rds-font-title-3`}>{heading}</h3>
        )}
        {subHeading && (
          <h4 className={`${subHeading} rds-font-product-title-1`}>
            {subHeading}
          </h4>
        )}
        <div className={`${styles.serviceTrayBtn} mb-4`}>{renderAction}</div>
      </>
    );
  };

  const renderServiceAreaMessage = () => {
    return (
      <div
        className={`border-bottom d-flex justify-content-center align-items-center p-2`}
      >
        <h3 className={`rds-font-title-5 text-center`}>{copyAboveLocationsListing}</h3>
      </div>
    );
  };

  const renderServiceAreaDisclimer = () => {
    if (variant === "Overlay") {
      return (
        <div className={`${styles.footer} rds-font-caption pb-3 text-center`}>
          {copyBelowLocationsListing}
        </div>
      );
    }
  };

  const generateStateArrays = (stateList, subSectionQty) => {
    const cityCount = stateList?.length;
    const iterator = Math.ceil(cityCount / subSectionQty);
    const stateArrays = [];

    for (let i = 0; i < subSectionQty; i++) {
      const startIndex = i * iterator;
      const endIndex = startIndex + iterator;
      const subsectionArray = stateList.slice(startIndex, endIndex);
      stateArrays.push(subsectionArray);
    }
    return stateArrays;
  };

  const generateStateTrayRenderableSubsectionsRegular = (statesArrays) => {
    const bootStrapClassMapper = {
      4: "col-12 col-sm-6 col-lg-3",
      3: "col-md-4",
      2: "col-6",
    };
    const bootStrapClasses =
      bootStrapClassMapper[statesArrays.length] ||
      "col-12 col-sm-6 col-md-4 col-lg-3";
    if (!bootStrapClassMapper[statesArrays.length]) {
      console.warn("Number of tray subsections did not match expected values");
    }

    return (
      <div className="row">
        {statesArrays.map((stateArray, index) => (
          <div key={index} className={`${bootStrapClasses} `}>
            {stateArray.map((stateData, index) => (
              <div key={index + "list"} className="mb-3">
                {variant === "Overlay" ? (
                  <a
                    href={`#${stateData.serviceAreaState}`}
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default anchor behavior
                      handleScrollTo(stateData.serviceAreaState); // Trigger scrolling
                    }}
                  >
                    <h2
                      className={`${styles.state} rds-font-product-title-4 text-start `}
                    >
                      {stateData.serviceAreaState}
                    </h2>
                  </a>
                ) : (
                  <h2
                    className={`${styles.state} rds-font-product-title-4 text-start text-decoration-none`}
                  >
                    {stateData.serviceAreaState}
                  </h2>
                )}
                {stateData.serviceAreaCityDetails &&
                  stateData.serviceAreaCityDetails.map((city, index) =>
                    city && city.redirectUrl ? (
                      <div key={index + "item"}>
                        {city?.openInNewTab ? (
                          <a
                            href={city.redirectUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`${styles.link} rds-font-body-1`}
                          >
                            {city.city}
                          </a>
                        ) : (
                          <Link
                            to={city.redirectUrl}
                            target="_self"
                            className={`${styles.link} rds-font-body-1`}
                          >
                            {city.city}
                          </Link>
                        )}
                      </div>
                    ) : (
                      <div key={index + "item"} className={styles.linkDisabled}>
                        {city.city}
                      </div>
                    )
                  )}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const renderServiceAreaListMobile = () => {
    const stateArrays = generateStateArrays(serviceAreaDetails, 2);
    return generateStateTrayRenderableSubsectionsRegular(stateArrays);
  };

  const renderServiceAreaListDesktop = () => {
    const stateArrays = generateStateArrays(serviceAreaDetails, 3);
    return generateStateTrayRenderableSubsectionsRegular(stateArrays);
  };

  const renderServiceAreaListTablet = () => {
    const stateArrays = generateStateArrays(serviceAreaDetails, 2);
    return generateStateTrayRenderableSubsectionsRegular(stateArrays);
  };

  return (
    <div
      className={
        variant === "Overlay" ? "rds-wrapper container-fluid p-0" : "rds-wrapper container pt-5 pb-5"
      }
      id={anchorTagValue}
    >
      <div className="row ">
        <div className={`col-12 col-md-6 ${columnAlignment} `}>
          {renderFeature()}
        </div>
        <div className={`col-12  col-md-6 `}>
          {variant === "Default"
            ? renderServiceAreaContent()
            : renderServiceAreaMessage()}
          <div className="d-none d-lg-block p-3">
            {/* Render for large screens (desktop) */}
            {renderServiceAreaListDesktop()}
          </div>

          <div className="d-none d-md-block d-lg-none p-3">
            {/* Render for medium screens (tablet) */}
            {renderServiceAreaListTablet()}
          </div>

          <div className="d-block d-md-none p-3">
            {/* Render for small screens (mobile) */}
            {renderServiceAreaListMobile()}
          </div>
          {renderServiceAreaDisclimer()}
        </div>
      </div>
    </div>
  );
};
export default RdsServiceAreasTray;
