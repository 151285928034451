import React from "react";
import { useSpring, animated } from "@react-spring/web";

import Logo from "../Logo";
import FaArrowLeft from "../../../../SvgIconComponents/Arrows/FaArrowLeft";
import CloseButton from "../../../../SvgIconComponents/CloseButton/CloseButton";
import styles from "./logopanel.module.css";

const LogoPanel = (props) => {
    const {
        handleClick,
        handleMenuToggle,
        handleBackArrow,
        isSubmenuActive,
        logo,
    } = props;

    const backBtnStyles = useSpring({
        from: {
            opacity: 0,
        },
        opacity: 1,
        config: { mass: 1, tension: 35, friction: 10 },
    });

    return (
        <div className={`row align-items-center ${styles.logoPanel}`}>
            <div className="col">
                <animated.button
                    className={`${isSubmenuActive ? "d-flex" : "d-none"} ${styles.backButton} bg-transparent rounded-circle border-0`}
                    onClick={handleBackArrow}
                    style={{ ...backBtnStyles }}
                >
                    <FaArrowLeft />
                </animated.button>
            </div>
            <div className="col">
                <Logo {
                    ...{
                        ...logo,
                        imgRatio: { width: "100", height: "42" },
                        imageClass: styles.image,
                        clickHandler: handleMenuToggle
                    }
                }
                />
            </div>
            <div className="col d-flex justify-content-end">
                <button
                    className={`rounded-circle border-0 bg-transparent`}
                    onClick={handleClick}
                >
                    <CloseButton />
                </button>
            </div>
        </div>
    );
};

export default LogoPanel;
