import React from "react";
import styles from "../../stylesheets/rds-footer.module.css";
import BusinessLogos from "./BusinessLogos";
import Address from "./Address";
import CopyRight from "./CopyRight";
import FooterNavBar from "./FooterNavBar";
import SocialMedia from "./SocialMedia";

const RdsFooter = (props) => {
  const { socialMediaItems, addressItems, footerNavItems, defaultFooter } =
    props;

  const {
    defaultFooterNavItems,
    businessLogoItems,
    copyRightContent,
    privacyChoiceContent,
    copyRightLinks,
  } = defaultFooter;

  const footerTheme = "#cacaca";
  const isSocialMediaLinks = socialMediaItems?.socialMediaLinks?.length > 0;
  const isAddressLinks = addressItems?.addressLinks?.length > 0;

  return (
    <div className={`${styles.footerContainer}`}>
      <div className={`container py-3`}>
        <div className="d-flex flex-wrap align-items-end pt-0 pt-sm-3">
          <div className="d-flex flex-column">
            <div className=" d-flex flex-wrap align-items-start gap-4">
              <div className={`pb-3 me-auto`}>
                <BusinessLogos businessLogoItems={businessLogoItems} />
              </div>
              {isSocialMediaLinks && <SocialMedia socialMediaItems={socialMediaItems} />}
              {isAddressLinks && <Address addressItems={addressItems} />}
            </div>
          </div>
          <div className={styles.mobileHr}></div>

          <div
            className={`py-3 d-flex justify-content-end align-items-end gap-4 flex-grow-1 flex-shrink-0 flex-basis-0 align-self-stretch`}
          >
            <FooterNavBar
              footerNavItems={
                footerNavItems ? footerNavItems : defaultFooterNavItems
              }
              footerTheme={footerTheme}
            />
          </div>
        </div>
        <div className={styles.hr}></div>
        <div className={`d-flex py-3 align-items-center flex-shrink-0`}>
          <div className={`d-flex pt-2 align-items-end gap-1`}>
            <CopyRight
              copyRightContent={copyRightContent}
              copyRightLinks={copyRightLinks}
              privacyChoiceContent={privacyChoiceContent}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RdsFooter;
