import { BACKGROUND_STYLES } from "./constant.js";
import "../src/index.css";

export const formInputValidations = {
  firstName: {
    maxLength: 30,
  },
  lastName: {
    maxLength: 30,
  },
  emailAddress: {
    pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  },
  phoneNumber: {
    pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}(?:\s(?:x)[0-9]{1,4})?$/im,
    // Includes characters used with formating "(", ")", "x", etc
    maxLength: 14,
  },
  scheduledDate: {
    pattern: /^(\d{2})(\/)(\d{2})(\/)(\d{4})$/,
    min: 1,
    max: 49,
  },
  zipCode : {
    pattern: /^(\d{5})$/,
    maxLength: 5,
  },
}

export function getTrayStyle(backgroundColor) {
  let trayStyle = {};
  if (
    backgroundColor?.toUpperCase().includes("BLACK") &&
    backgroundColor?.toUpperCase().endsWith("_TRIANGLE")
  ) {
    trayStyle = {
      backgroundColor: "var(--color-dark-gray)",
    };
  } else if (backgroundColor?.toUpperCase().endsWith("_TRIANGLE")) {
    trayStyle = {
      backgroundImage: BACKGROUND_STYLES.Triangle(backgroundColor),
    };
  } else if (backgroundColor?.toUpperCase() === "RED") {
    trayStyle = {
      backgroundColor: "var(--color-primary)",
    };
  } else if (backgroundColor?.toUpperCase() === "GRAY") {
    trayStyle = {
      backgroundImage: BACKGROUND_STYLES.Gray,
    };
  } else {
    trayStyle = {
      backgroundColor: backgroundColor,
    };
  }

  return trayStyle;
}

export function getTextStyle(backgroundColor) {

  if (backgroundColor?.toUpperCase() === "RED") {
    return {
      color: "var(--color-white)",
    };
  } else if (
    backgroundColor?.toUpperCase().includes("BLACK") &&
    backgroundColor?.toUpperCase().endsWith("_TRIANGLE")
  ) {
    return {
      color: "var(--color-white)",
    };
  }
}

export const handleTabChange = (
  value,
  setActiveItem,
  isFranchiseRibbonActive = false
) => {
  const element = document.getElementById(value);
  if (element) {
    const yOffset = -100;
    const targetY =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    const startingY = window.pageYOffset;
    const diff = targetY - startingY;

    const duration = 500;
    let start;

    const step = (timestamp) => {
      if (!start) start = timestamp;
      const time = timestamp - start;
      const percent = Math.min(time / duration, 1);

      if (isFranchiseRibbonActive) {
        window.scrollTo(0, startingY + diff * percent - 68);
      } else {
        window.scrollTo(0, startingY + diff * percent + 20);
      }

      //window.scrollTo(0, targetY+startingY-offsetStickyHeader);
      if (time < duration) {
        window.requestAnimationFrame(step);
      } else {
        setActiveItem(value);
      }
    };

    window.requestAnimationFrame(step);
  }
};

export const validateEmail = (value) => {
  const reEmail =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  let isSuccess = true;
  if (!value?.match(reEmail)) {
    isSuccess = false;
  }
  return isSuccess;
};

export const validatePhoneNumber = (value) => {
  const rePhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  let isSuccess = true;
  if (!value?.match(rePhone)) {
    isSuccess = false;
  }
  return isSuccess;
};

//Eg: 1234567890 > (123) 456-7890
export const formatPhoneNumber = (phone) => {
  phone = phone.replace(/\D/g, '');
  const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})(\d{0,4})$/);
  if (match) {
    phone = `(${match[1]}${match[2] ? ') ' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}${match[4] ? ' x' : ''}${match[4]}`;
  }
  return phone;
}

export const getDateInputISO = (days = 0) => {
  let date = new Date();
  date.setDate(date.getDate() + days);
  date = date.toISOString().split("T")[0];
  return date;
}

//get data from LocalStorage
export const getFranchiseDataFromLS = (typeOfData) => {
  if (localStorage.getItem(typeOfData?.toString())) {
    return localStorage.getItem(typeOfData?.toString())
  }
}

export const getBootstrapColSplit = (dimensions) => {
  const splitDimensions = dimensions.split("-");
  // Calculate start column
  const startCol = Math.round(parseInt(splitDimensions[0] / (100 / 12)))
  // Use start column to calculate end column
  const endCol = 12 - startCol;
  return { startCol, endCol };
} 