import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAboutUs } from "../../redux-toolkit/slice/aboutUs/aboutUsSlice";
import DynamicTrays from "../../Components/DynamicTrays";
import ErrorPage from "../../Components/ErrorPage";
import useMetaDetails from "../../hooks/useMetaDetails";
import useDisplayMsgForExternalFranchise from "../../hooks/useDisplayMsgForExternalFranchise";

const AboutUs = () => {
  const { data, isError, statusCode } = useSelector(
    (state) => state.aboutUs
  );
  const externalFranchiseMessage =
    useDisplayMsgForExternalFranchise("officesource");
  const dispatch = useDispatch();
  const [isPageContainsError, setIsPageContainsError] = useState(false);

  useMetaDetails(data);

  useEffect(() => {
    dispatch(fetchAboutUs());
  }, []);

  useEffect(() => {
    setIsPageContainsError(isError);
  }, [isError, statusCode]);

  return (
    <>
      {data?.trays?.map((tray, index) => {
        return (
          <DynamicTrays
            {...tray}
            key={index}
            externalFranchiseMessage={externalFranchiseMessage}
          />
        );
      })}

      {isPageContainsError && <ErrorPage statusCode={statusCode} />}
    </>
  );
};

export default AboutUs;