import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchFranchisePromotions } from "../../redux-toolkit/slice/franchisePromotions/franchisePromotionsSlice";
import DynamicTrays from "../../Components/DynamicTrays";
import ErrorPage from "../../Components/ErrorPage";
import useMetaDetails from "../../hooks/useMetaDetails";

const FranchisePromotions = () => {
  const { franchiseName } = useParams();
  const { data, isError, statusCode } = useSelector(
    (state) => state.franchisePromotions
  );
  const dispatch = useDispatch();
  const [isPageContainsError, setIsPageContainsError] = useState(false);

  useMetaDetails(data);

  useEffect(() => {
    const payLoad = `?franchisename=${franchiseName}`;
    dispatch(fetchFranchisePromotions(payLoad));
  }, [franchiseName]);

  useEffect(() => {
    setIsPageContainsError(isError);
  }, [isError, statusCode]);

  return (
    <>
      {data?.trays?.map((tray, index) => {
        return <DynamicTrays {...tray} key={index} />;
      })}

      {isPageContainsError && <ErrorPage statusCode={statusCode} />}
    </>
  );
};

export default FranchisePromotions;
