import React from "react";
import PropTypes from "prop-types";
import RdsCategory from "./rds-category";
import "../stylesheets/rds-common.css";
import styles from "../stylesheets/rds-category-blog-tray.module.css";

const RdsCategoryBlogTray = ({
  heading,
  cta,
  blogCategories,
  handleSeeAllBlogPosts,
  handleRedirectFromBLP,
}) => {
  const handleRedirect = (selectedCategory, redirectURL) => {
    handleRedirectFromBLP?.({ selectedCategory, redirectURL });
  };

  const handleSeeAllBlogPostsRedirect = (value) => {
    handleSeeAllBlogPosts?.(value);
  };

  return (
    <div className={`${styles.wrapper} pt-4 pb-4`}>
      <div className="container ">
        <div className="row ">
          <div className={`col-6`}>
            <h2 className={`${styles.title} rds-font-title-5`}>{heading}</h2>
          </div>
          <div className="col-6 d-flex justify-content-end">
            <span
              className={`${styles.link} rds-font-subtitle-1`}
              onClick={() => {
                handleSeeAllBlogPostsRedirect(cta?.redirectUrl);
              }}
            >
              {cta?.text}
            </span>
          </div>
        </div>
        <div className="row">
          {blogCategories?.map((category) => (
            <div
              className={`col-4 col-6 col-sm-4 col-md-3 col-lg-3 mb-2`}
              key={category?.categoryName}
            >
              <RdsCategory
                label={category?.categoryName}
                handleClick={(prop) =>
                  handleRedirect(prop, category?.redirectUrl)
                }
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

RdsCategoryBlogTray.propTypes = {
  heading: PropTypes.string,
  handleSeeAllBlogPosts: PropTypes.func,
  handleRedirectFromBLP: PropTypes.func,
  cta: PropTypes.object,
  blogCategories: PropTypes.arrayOf(
    PropTypes.shape({
      categoryName: PropTypes.string.isRequired,
      redirectUrl: PropTypes.string.isRequired,
    })
  ),
};

export default RdsCategoryBlogTray;
