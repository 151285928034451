import React from "react";
import '../stylesheets/rds-textlink.css';
 
class RdsTextLink extends React.Component{
  render(){
    let value;
    if(this.props.iconType === "leading"){
      value =  <React.Fragment><i className={this.props.iconClass}></i>{this.props.text}</React.Fragment>;
    } else if (this.props.iconType === "trailing"){
      value =  <React.Fragment>{this.props.text}<i className={this.props.iconClass}></i></React.Fragment>;
    } else {
      value = this.props.text;
    }

    
    var inputProps = {
      id: this.props.id,
      className: this.props.linkClass,
      tabIndex:this.props.tabIndex,
      rel:this.props.rel || "noopener noreferrer"
    };
    if(this.props.target){
      inputProps.target = "_blank"
    }
    if(this.props.link){
      inputProps.href = this.props.link
    }
    else if(this.props.onClick){
      // inputProps.href ="javascript:void(0);"
      inputProps.onClick =(e)=> {
        e.preventDefault();
        this.props.onClick(e);
      }
    }
    
    return(
      <>{this.props.link ? <a {...inputProps} >{value}</a> : <button {...inputProps} >{value}</button>}</>
    );
  }
}

export default RdsTextLink;
