import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchFranchiseContact } from "../../redux-toolkit/slice/franchiseContact/franchiseContactSlice";
import { dataPush, CONTACT_US_SUBMISSION } from "../../Utilities/GAnalytics";
import NetworkManager from "../../redux-toolkit/networkManager/networkConfig";
import { getFranchiseHeaderDetailLocalStorage, useIsMobile } from "../../Utilities/Utility";
import DynamicTrays from "../../Components/DynamicTrays";
import ErrorPage from "../../Components/ErrorPage";
import useMetaDetails from "../../hooks/useMetaDetails";
import useDisplayMsgForExternalFranchise from "../../hooks/useDisplayMsgForExternalFranchise";
import FranchiseExternalMessageTray from "../../AceHomeServicesRds/src/components/rds-external-system-message";


const FranchiseContactUs = () => {
  const isMobile = useIsMobile();
  const { franchiseName } = useParams();
  const [formSuccess, setFormSuccess] = useState(false);
  const { data, isError, statusCode } = useSelector(
    (state) => state.franchiseContact
  );
  const externalFranchiseMessage =
    useDisplayMsgForExternalFranchise("officesource");
  const dispatch = useDispatch();
  const [isPageContainsError, setIsPageContainsError] = useState(false);
  const [formSubmissionError, setFormSubmissionError] = useState(false);
  const navigate = useNavigate();

  useMetaDetails(data);

  useEffect(() => {
    const payLoad = `?FranchiseName=${franchiseName}`;
    dispatch(fetchFranchiseContact(payLoad));
  }, [franchiseName]);

  useEffect(() => {
    setIsPageContainsError(isError);
  }, [isError, statusCode]);

  useEffect(() => {
    if (formSuccess) {
      const pathNameList = window.location.pathname.split("/");
      navigate(
        `/${pathNameList[1]}/${pathNameList[2]}/contact-us-confirmation`,
        { state: { data: data?.contactUsConfirmationForm } }
      );
    }
  }, [formSuccess]);

  const handleSubmit = (userData) => {
    const franchiseHeaderDetailLocalStorage = getFranchiseHeaderDetailLocalStorage();
    const franchiseData = {
      name:
      franchiseHeaderDetailLocalStorage?.headerFranchiseName || "",
      phoneNumber:
      franchiseHeaderDetailLocalStorage?.headerFranchisePhone || "",
      emailAddress:
      franchiseHeaderDetailLocalStorage?.headerFranchiseEmail || "",
    };

    const formData = {
      formName: "CONTACT_US",
      userData,
      franchiseData,
    };

    NetworkManager.franchiseSendEmail(formData).then((response) => {
      if (response.status === 200) {
        dataPush(
          CONTACT_US_SUBMISSION,
          franchiseHeaderDetailLocalStorage?.headerFranchiseName,
          franchiseHeaderDetailLocalStorage?.headerFranchiseAddress,
          window.location.href
        );
        setFormSuccess(true);
      } else {
        setFormSubmissionError(true);
        setIsPageContainsError(true);
      }
    });
  };

  return (
    <>
      {
        Boolean(externalFranchiseMessage) && <FranchiseExternalMessageTray externalFranchiseMessage={externalFranchiseMessage} />
      }
      <div>
        <div
          className="rds-wrapper"
          style={{
            backgroundImage: "url('/Black_Triangle.png')",
            minHeight: "200px",
          }}
        ></div>
        <div className="container mb-5 pb-5">
          <div
            className={isMobile ? "m-1" : "mx-5"}
            style={{
              background: "white",
              marginTop: "-10%",
              padding: isMobile ? "0px" : "20px",
              paddingLeft: isMobile ? "2px" : "50px",
              paddingRight: isMobile ? "2px" : "50px",
            }}
          >
            {data?.contactUsForm && !formSuccess && (
              <DynamicTrays
                {...data?.contactUsForm}
                onFormSubmission={handleSubmit}
                formSubmissionError={formSubmissionError}
                externalFranchiseMessage={externalFranchiseMessage}
              />
            )}
          </div>
          {isPageContainsError && <ErrorPage statusCode={statusCode} />}
        </div>
      </div>
    </>
  );
};

export default FranchiseContactUs;
