import React from 'react'

const Plumbing = ({ fillColor = "#ffffff" }) => {
    return (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.775 10.3283H15.3463C14.7175 10.3283 14.1662 9.90795 13.9996 9.30158C13.8331 8.6952 13.2818 8.2749 12.6529 8.2749H9.37069C8.74186 8.2749 8.19057 8.6952 8.02402 9.30158C7.85748 9.90795 7.30618 10.3283 6.67735 10.3283H4.94922V14.4024H6.67195C7.3033 14.4024 7.85617 14.826 8.02038 15.4356L8.02762 15.4624C8.19187 16.0721 8.7447 16.4957 9.37605 16.4957H12.6475C13.2789 16.4957 13.8317 16.0721 13.9959 15.4624L14.0032 15.4356C14.1674 14.826 14.7202 14.4024 15.3516 14.4024H15.9183C17.0041 14.4024 17.8843 15.2826 17.8843 16.3684V16.6684H21.9585V15.5118C21.9585 12.649 19.6378 10.3283 16.775 10.3283Z" stroke={fillColor} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20.9653 20.3559C20.9653 20.9331 20.4974 21.401 19.9202 21.401C19.3429 21.401 18.875 20.9331 18.875 20.3559C18.875 19.7786 19.9202 18.7266 19.9202 18.7266C19.9202 18.7266 20.9653 19.7787 20.9653 20.3559Z" stroke={fillColor} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.8125 8.24036V6.61914" stroke={fillColor} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.5081 4.70312H7.10938V6.47208H14.5081V4.70312Z" fill={fillColor} />
            <path d="M4.94922 9.71094V15.0595" stroke={fillColor} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M22.6972 16.6729H17.1094" stroke={fillColor} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default Plumbing