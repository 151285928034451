import React from "react";
import styles from "./rds-address.module.css";

const Address = ({ addressItems = {} }) => {
  const { addressText = "", addressLinks = [] } = addressItems;
  return (
    <div className={`${styles.addressContainer}`}>
      <h4 className={`m-0 ${styles.heading}`}>{addressText}</h4>
      <ul>
        {addressLinks?.slice(0, 3).map((item, index) => (
          <li key={index} className={`d-flex align-items-center`}>
            <div className={styles.addressText}>
              <div>{item.addressLine1}</div>
              <div>{item.addressLine2}</div>
            </div>
            {index < addressLinks.length - 1 && (
              <span className={styles.seperator}></span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Address;
