import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Markup } from 'interweave';
import { useIsMobile } from "../../Utilities/Utility";
import { fetchSitemap } from "../../redux-toolkit/slice/sitemap/sitemapSlice";
import styles from "./Sitemap.module.css"
import ErrorPage from "../../Components/ErrorPage";
import useMetaDetails from "../../hooks/useMetaDetails";
const Sitemap = () => {

  const isMobile = useIsMobile();
  const { data, isError, statusCode } = useSelector(
    (state) => state.sitemap
  );
  const dispatch = useDispatch();
  const [isPageContainsError, setIsPageContainsError] = useState(false);

  useMetaDetails(data);
  
  useEffect(() => {
    dispatch(fetchSitemap());
  }, []);

  useEffect(() => {
    setIsPageContainsError(isError);
  }, [isError, statusCode])

  return (
    <div className={`container mb-5 ${!isMobile ? styles.sitemapWrapper : ''}`}>
      <div className={`pt-4 ${styles.accentText}`}>
        {data?.lastUpdated}
      </div>
      {data && (
        <>
          <h1 className={styles.heading}>{data.pageHeading}</h1>
          <div className={styles.sitemap}>
            <Markup content={data.pageDescription} />
          </div>
        </>
      )}
      {
        isPageContainsError && <ErrorPage statusCode={statusCode} />
      }
    </div>
  )
}

export default Sitemap
