import React, { useState, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";

import MenuList from "./MenuList";
import SubMenu from "./SubMenu";
import CommonMenu from "./CommonMenu";
import styles from "./multilevelmenu.module.css";

const MultiLevelMenu = (props) => {
    const {
        menuData = [],
        commonMenu = [],
        handleMenuToggle = {},
        updateMenu = {},
        isSubmenuActive = false,
        isMainMenuActive = false,
        logoPanelRef = {},
        ctaPanelRef = {},
    } = props;

    const [transition, setTransition] = useState(true);

    useEffect(() => {
        setTransition(!transition);
    }, [menuData]);
   
    const handleNextArrow = (menuLabel) => {
        const activeMenuObj = menuData?.menuItems?.filter(
            (menu) => menu.menuText?.toUpperCase() === menuLabel?.toUpperCase()
        )[0];

        updateMenu(activeMenuObj, true, false);
    };

    const mainMenuStyles = useSpring({
        position: transition ? "absolute" : "relative",
        left: isSubmenuActive ? "-100%" : "0",
    });

    return (
        <animated.div
            className={styles.mainMenuContainer}
            style={{ ...mainMenuStyles }}
        >
            {isMainMenuActive && (
                <>
                    <ul className={styles.menuList}>
                        {menuData?.menuItems?.map((menu, index) => {
                            return (
                                <MenuList
                                    data={menu}
                                    handleMenuToggle={handleMenuToggle}
                                    handleNextArrow={() => handleNextArrow(menu.menuText)}
                                    hasSubMenu={menu.headerSubMenu}
                                    key={index}
                                />
                            );
                        })}
                    </ul>
                </>
            )}
            {isSubmenuActive && (
                <SubMenu {
                    ...{
                        data: menuData,
                        handleMenuToggle,
                        handleNextArrow,
                        logoPanelRef,
                        ctaPanelRef
                    }
                }

                />
            )}
            {!isSubmenuActive && (
                <CommonMenu {...{ commonMenuData: commonMenu, handleMenuToggle }} />
            )}
        </animated.div>
    );
};

export default MultiLevelMenu;
