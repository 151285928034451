import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated } from "@react-spring/web";

import MultiLevelMenu from "./MultiLevelMenu";
import LogoPanel from "./MultiLevelMenu/LogoPanel";
import FranchiseInfo from "./MultiLevelMenu/FranchiseInfo";
import PhonePanel from "./MultiLevelMenu/PhonePanel";
import ServiceRequestCTA from '../ServiceRequestCTA';
import styles from "./mobileslidepanel.module.css";

const MobileSlidePanel = (props) => {
  const {
    handleMenuToggle = {},
    logo = {},
    headerMenu = [],
    headerCommonMenu = [],
    lastVisitedFranchise = {},
    isLastVisitedFranchiseRibbonActive = false,
    serviceRequestData = {},
  } = props;

  const {
    franchiseName,
    franchiseAddress,
    franchisePhoneNumber,
    recentFranchisePath
  } = lastVisitedFranchise;

  const logoPanelRef = useRef(null);
  const ctaPanelRef = useRef(null)

  const [activeMenus, setActiveMenus] = useState({
    menuItems: headerMenu,
    menuTitle: "",
  });

  const [menuHistory, setMenuHistory] = useState([
    {
      menuItems: headerMenu,
      menuTitle: "",
    },
  ]);

  const [isSubmenuActive, setSubMenuActive] = useState(false);
  const [isMainMenuActive, setIsMainMenuActive] = useState(true);

  useEffect(() => {
    if (menuHistory?.length > 1) {
      setSubMenuActive(true);
    } else {
      setSubMenuActive(false);
      setIsMainMenuActive(true);
    }
  }, [menuHistory]);

  const updateMenu = (currentMenu, subMenuFlag, mainMenuFlag) => {
    const { menuText, headerSubMenu } = currentMenu;
    setSubMenuActive(subMenuFlag);
    setIsMainMenuActive(mainMenuFlag);
    setMenuHistory((prevState) => {
      let currentState = [...prevState];
      currentState.push({
        menuTitle: menuText,
        menuItems: currentMenu?.headerSubMenu,
      });
      return currentState;
    });
    setActiveMenus({ menuTitle: menuText, menuItems: headerSubMenu });
  };

  const handleBackArrow = () => {
    const menuHistoryLen = menuHistory?.length;
    setMenuHistory((prevState) => {
      let currentState = [...prevState];
      if (menuHistoryLen > 1) {
        currentState = currentState.slice(0, currentState?.length - 1);
      }
      return currentState;
    });
    if (menuHistoryLen - 2 >= 0) {
      setActiveMenus({
        menuTitle: menuHistory[menuHistoryLen - 2]?.menuTitle,
        menuItems: menuHistory[menuHistoryLen - 2]?.menuItems,
      });
    }
  };

  //Using react-spting/web animation
  //Step 1 - setting the animation properties like left from -100% to 0%
  const overLayStyles = useSpring({
    from: {
      display: "none",
      opacity: 0,
    },
    display: "block",
    opacity: 1,
    config: { mass: 1, tension: 35, friction: 10 },
  });

  const [springs, api] = useSpring(() => ({
    from: { left: "-100%" },
    left: "0%",
    config: { mass: 1, tension: 35, friction: 10 },
  }));

  const handleClick = () => {
    api.start({
      from: {
        left: "0%",
        opacity: 1,
      },
      to: {
        left: "-100%",
        opacity: 0,
      },
    });
    setTimeout(() => {
      handleMenuToggle();
    }, 600);
  };

  return (
    <>
      {/* Step 2 - Overlay container, onClick handler closes the menu  */}
      <div
        className={styles.mobileSlidePanelContainer}
        style={{ ...overLayStyles }}
        onClick={handleClick}
      />
      {/* Step 3 - assigning the animated div component */}
      <animated.div className={styles.mobileSlideMenu} style={{ ...springs }}>
        <div className={styles.menuContainer}>
          <div className={styles.topPanel} ref={logoPanelRef}>
            <LogoPanel
              {...{
                handleBackArrow,
                handleClick,
                handleMenuToggle,
                isSubmenuActive,
                logo
              }}
            />
            {
              isLastVisitedFranchiseRibbonActive &&
              <FranchiseInfo {...lastVisitedFranchise} />
            }
          </div>
          {/* Place for Multilevel menu */}
          <div className={`${styles.multilevelMenuContainer}`}>
            <MultiLevelMenu
              {
              ...{
                menuData: activeMenus,
                commonMenu: headerCommonMenu,
                handleMenuToggle,
                updateMenu,
                isSubmenuActive,
                isMainMenuActive,
                logoPanelRef,
                ctaPanelRef,
              }}
            />
          </div>
          {isLastVisitedFranchiseRibbonActive &&
            <div className={`${styles.CTAPanel} `} ref={ctaPanelRef}>
              <ServiceRequestCTA
                {
                ...{
                  ...serviceRequestData,
                  recentFranchisePath,
                  handleMenuToggle
                }
                }
              />
              <PhonePanel
                {
                ...{
                  franchiseName,
                  franchiseAddress,
                  franchisePhoneNumber
                }
                }
              />
            </div>
          }
        </div>
      </animated.div>
    </>
  );
};

export default MobileSlidePanel;
