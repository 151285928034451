import React from "react";

import StepsCard from "./StepsCard";

const StepsPanel = ({ contentData = [] }) => {
  return (
    <div className={`row justify-content-center`}>
      {contentData.map((cardData, index) => {
        return (
          <div className={`col-sm-12 col-md-4 mb-4`} key={index}>
            <StepsCard cardData={cardData} />
          </div>
        );
      })}
    </div>
  );
};

export default StepsPanel;
