import React from 'react'

const LinkArrow = ({ linkColor }) => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Trailing Icon">
                <path id="Vector" stroke="none" d="M9.32827 2.43144L15.0897 7.93098C15.2452 8.0793 15.3332 8.28485 15.3332 8.49992C15.3332 8.71499 15.2452 8.92024 15.0897 9.06886L9.32827 14.5684C9.01421 14.8671 8.51709 14.8558 8.21723 14.5418C7.91751 14.2308 7.92877 13.7316 8.24383 13.4308L12.5878 9.28647H1.45248C1.01874 9.28647 0.666504 8.93423 0.666504 8.50049C0.666504 8.06675 1.01874 7.71517 1.45248 7.71517H12.5858L8.24178 3.57088C7.9272 3.26947 7.91738 2.77026 8.21527 2.45829C8.51643 2.14403 8.98455 2.1329 9.32827 2.43144Z" fill={linkColor} />
            </g>
        </svg>

    )
}

export default LinkArrow
