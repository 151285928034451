import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMetaDescription, setMetaTitle } from "../redux-toolkit/slice/metaSlice/metaSlice";

 const useMetaDetails = (metaData) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (metaData?.title) {
            dispatch(setMetaTitle(metaData.title));
        }
        if (metaData?.metaDescription) {
            dispatch(setMetaDescription(metaData.metaDescription));
        }
    }, [metaData])
}

export default useMetaDetails