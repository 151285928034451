import AceLogo from "../assets/common/footer/Ace-Red-Logo.svg";
import YourPrivacyChoices from "../assets/common/footer/your_privacy_choice.jpg";

const dateVal = new Date();
let yearVal = dateVal.getFullYear();

export const defaultFooter = {
  businessLogoItems: [
    {
      name: "AceHardwareCorporate",
      url: "https://www.acehardwarehomeservices.com/",
      image: AceLogo,
      width: "100px",
      height: "100px",
      alt: "Ace Hardware logo",
      target: "_self",
    },
  ],
  copyRightContent: {
    copyRight: `© ${yearVal} Ace Hardware Home Services All Rights Reserved.`,
  },

  copyRightLinks: [
    {
      name: "Privacy Policy",
      uri: "/privacy-policy",
    },
    {
      name: "Terms of Use",
      uri: "/terms-and-conditions",
    },
    {
      name: "Site Map",
      uri: "/sitemap",
    },
  ],
  privacyChoiceContent: {
    privacyChoiceText: "Your Privacy Choices",
    privacyChoiceImg: YourPrivacyChoices,
    alt: "Your Privacy Choices",
    width: "29",
    height: "14",
    url: "https://privacyportal.onetrust.com/webform/08fcfb30-e571-44e6-a1de-eb62fe3d7b00/d31e7b56-6dcc-44e0-9a63-9189d1f7f5b3",
    target: "_blank",
  },
  defaultFooterNavItems: [
    {
      name: "Blog",
      openInNewTab: false,
      uri: "/blogs",
    },
    {
      name: "Ace Hardware",
      openInNewTab: true,
      uri: "https://acehardware.com",
    },
  ],
};
