import React from "react";

const Handyman = ({fillColor = "#000", strokeColor = "#000"}) => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1301 18.0363C11.433 19.0187 11.196 20.1317 10.4185 20.9092C9.71664 21.6111 8.7414 21.8722 7.83564 21.6936C7.70411 21.6677 7.65501 21.5049 7.74978 21.4101L9.14195 20.018L8.65505 18.6203L7.25735 18.1334L5.86523 19.5255C5.77023 19.6205 5.6076 19.5709 5.58164 19.439C5.39718 18.5027 5.68266 17.4922 6.43916 16.7863C7.2096 16.0672 8.28888 15.8562 9.24171 16.149"
        stroke={strokeColor}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.4221 11.9712L16.2734 9.11982L16.2835 9.10335C15.9806 8.12096 16.2175 7.00788 16.9951 6.23037C17.6969 5.52851 18.6722 5.26737 19.5779 5.44602C19.7095 5.47194 19.7585 5.63471 19.6638 5.72948L18.2716 7.12164L18.7585 8.51934L20.1562 9.00624L21.5483 7.61412C21.6433 7.51913 21.806 7.56876 21.8319 7.70057C22.0164 8.63693 21.7309 9.6474 20.9744 10.3534C20.204 11.0724 19.1247 11.2834 18.1718 10.9906L18.1612 11.0077L11.1328 18.0361"
        stroke={strokeColor}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.24219 16.1492L12.1287 13.2627"
        stroke={strokeColor}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.4937 10.3349L11.3643 10.2011C11.3303 10.1659 11.3116 10.1187 11.3122 10.0698L11.3406 8.00068C11.3416 7.93003 11.3023 7.86496 11.2393 7.83297L7.55961 5.96394C7.48815 5.92762 7.40139 5.94144 7.34473 5.99809L6.06988 7.27294C6.01318 7.32964 5.99941 7.41636 6.03573 7.48782L7.90476 11.1675C7.93675 11.2305 8.00182 11.2698 8.07247 11.2688L10.1416 11.2405C10.1905 11.2398 10.2377 11.2585 10.2729 11.2925L10.4067 11.4219L14.7235 15.7387C14.7959 15.8111 14.9131 15.8111 14.9855 15.7387L15.8106 14.9137C15.8829 14.8413 15.8829 14.724 15.8106 14.6517L11.4937 10.3349Z"
        stroke={strokeColor}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.0428 20.971C21.694 20.3198 21.694 19.2642 21.0428 18.613L15.4947 13.0649L13.1367 15.4229L18.6849 20.971C19.336 21.6221 20.3917 21.6221 21.0428 20.971Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default Handyman;
