import React from 'react'

const Location = ({ fillColor = '#121212' }) => {
    return (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.9809 23.5057C10.7321 20.7399 5.73047 14.0366 5.73047 10.2714C5.73047 5.70318 9.43365 2 14.0019 2C18.5684 2 22.2733 5.70318 22.2733 10.2714C22.2733 14.0366 17.2329 20.7399 15.0229 23.5057C14.493 24.1648 13.5108 24.1648 12.9809 23.5057ZM14.0019 13.0285C15.5226 13.0285 16.759 11.7921 16.759 10.2714C16.759 8.75067 15.5226 7.51427 14.0019 7.51427C12.4811 7.51427 11.2447 8.75067 11.2447 10.2714C11.2447 11.7921 12.4811 13.0285 14.0019 13.0285Z" fill={fillColor} />
        </svg>

    )
}

export default Location
