import React from "react";

import { MenuItemLink, MenuItemIcon } from "../../../MenuItems";
import FaChevronRight from "../../../../SvgIconComponents/Arrows/FaChevronRight";
import styles from "./menulist.module.css";

const MenuList = (props) => {
    const {
        data = {},
        hasSubMenu = false,
        handleMenuToggle = {},
        handleNextArrow = {},
    } = props;
    return (
        <li className={styles.menuListItem}>
            <div className={styles.menuElementRow}>
                <MenuItemLink {
                    ...{
                        ...data,
                        handleMenuToggle,
                        linkClasses: styles.menuElement
                    }}>
                    <MenuItemIcon {
                        ...{
                            iconFlag: data.showIcon,
                            iconName: data.iconName
                        }}
                    />
                    {data.menuText}
                </MenuItemLink>
                {hasSubMenu && hasSubMenu?.length > 0 && (
                    <button
                        className={`${styles.nextBtn} rounded-circle border-0 bg-transparent`}
                        onClick={() => handleNextArrow(data.menuText)}
                    >
                        <FaChevronRight fillColor={"#858585"} />
                    </button>
                )}
            </div>
        </li>
    );
};

export default MenuList;
