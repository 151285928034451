export const dataPush = (
  eventName,
  companyName,
  companyAddress,
  location,
  otherData
) => {
  let data = {};

  const companyDetails = {
    event: eventName,
    company_name: companyName,
    company_address: companyAddress,
    page_url: location,
  };

  const formData = {
    event: eventName,
    company_name: companyName,
    company_address: companyAddress,
    previous_page_url: location,
  };

  if (otherData && eventName === "phone_call_usage") {
    const additionalData = {
      ...companyDetails,
      page_title: otherData,
    };
    data = { ...additionalData };
  } else if (
    otherData &&
    (eventName === "phone_click" || eventName === "print")
  ) {
    const additionalData = {
      ...companyDetails,
      coupon_name: otherData,
    };
    data = { ...additionalData };
  } else if (eventName !== "phone_number_click") {
    data = { ...formData };
  } else {
    data = { ...companyDetails };
  }

  window.dataLayer.push({ ...data });
};
