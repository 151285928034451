import React from 'react';

import { FormatFranchise } from '../../LastVisitedFracnhise';
import { ACE_BUSINESS_NAME } from '../../../../constant';

const FranchiseInfo = (props) => {
    const {
        recentFranchisePath,
        franchiseName,
        franchiseCity,
        franchiseStateAbbreviation,
    } = props;
    return (
        <div className='text-center p-2 lh-1'>
            <FormatFranchise {
                ...{
                    franchiseName: franchiseName?.includes('Ace') ? franchiseName : `${ACE_BUSINESS_NAME} - ${franchiseName}`,
                    franchiseCity,
                    franchiseStateAbbreviation,
                    recentFranchisePath
                }} />
        </div>
    )
}

export default FranchiseInfo
