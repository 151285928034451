import React from "react";

import StepsPanel from "./StepsPanel";
import styles from "./HowItWorks.module.css";

const HowItWorks = ({ contentData = {} }) => {
  const { HowItWorksSection = {}, StepsOfHowItWorks = [] } = contentData;
  //bootstap class combined in one string
  const bsClasses = "col-12 d-flex justify-content-center";
  return (
    <div className="container">
      <div className="row mt-5 mb-5">
        <div className={`${bsClasses} mt-4`}>
          <h2 className={styles.heading}>{HowItWorksSection.title}</h2>
        </div>
        <div className={`${bsClasses}`}>
          <h3 className={styles.subHeading}>{HowItWorksSection.mainHeading}</h3>
        </div>
        <div className={`${bsClasses}`}>
          <p className={styles.description}>{HowItWorksSection.description}</p>
        </div>
      </div>
      <StepsPanel contentData={StepsOfHowItWorks} />
    </div>
  );
};

export default HowItWorks;
