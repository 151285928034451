import React from 'react'

const Phone = ({ fillColor = '#fff', width = "27", height = "27" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.6498 15.7633L19.4302 13.9543C18.5102 13.5555 17.4261 13.8207 16.8005 14.5966L15.6214 16.0361C13.6973 14.9297 12.0855 13.3183 10.9808 11.4341L12.4224 10.2533C13.192 9.62512 13.4575 8.54745 13.0678 7.63006L11.2489 3.35506C10.8123 2.35818 9.73037 1.81419 8.67634 2.05783L4.75541 2.96276C3.7293 3.19737 3.01172 4.09843 3.01172 5.15375C3.01172 15.548 11.4637 24 21.858 24C22.9133 24 23.8144 23.2828 24.0107 22.2585L24.9157 18.3337C25.201 17.2758 24.651 16.1929 23.6498 15.7633ZM22.9451 17.8687L22.0389 21.7965C22.0187 21.8438 21.9482 21.9375 21.8576 21.9375C12.602 21.9375 5.07385 14.4093 5.07385 5.15379C5.07385 5.06382 5.12958 4.99335 5.21754 4.97319L9.14234 4.06741C9.15576 4.06405 9.16986 4.0627 9.18329 4.0627C9.2578 4.0627 9.32762 4.10769 9.35719 4.17481L11.1679 8.39996C11.2001 8.47585 11.1786 8.56445 11.1142 8.61747L9.0188 10.2976C8.64819 10.601 8.5354 11.12 8.7462 11.5497C10.1646 14.4385 12.5387 16.8134 15.4279 18.2314C15.8189 18.4422 16.3771 18.3294 16.6809 17.9588L18.4001 15.8589C18.4477 15.7985 18.537 15.7756 18.6116 15.8106L22.8337 17.6191C22.915 17.6926 22.9623 17.7828 22.9451 17.8687Z" fill={fillColor} />
        </svg>

    )
}

export default Phone
