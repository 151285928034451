import React from "react";
import { Link } from "react-router-dom";

import FaChevronRight from "../../SvgIconComponents/Arrows/FaChevronRight";
import styles from "./rds-footernavbar.module.css";

const FooterNavBar = ({ footerNavItems = [], footerTheme = "#fff" }) => {
  return (
    <ul
      className={`d-flex flex-column flex-sm-row justify-content-start justify-content-md-end  ${styles.navbarContainer}`}
    >
      {footerNavItems.map((item, index) => (
        <li key={index}>
          <div
            className={`d-flex d-sm-block justify-content-between ${styles.navLinksFooter}`}
          >
            <Link
              to={item.uri}
              target={item?.openInNewTab ? "_blank" : "_self"}
              rel={item?.openInNewTab ? "noopener noreferrer" : ""}
            >
              {item.name}
            </Link>
            <Link to={item.uri}>
              <FaChevronRight fillColor={footerTheme} />
            </Link>
          </div>
          <div className={styles.mobileHr}></div>
        </li>
      ))}
    </ul>
  );
};

export default FooterNavBar;
