import React, { Component } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { getTrayStyle, getTextStyle } from "../utility";
import "../stylesheets/rds-bootstrap.css";
import styles from "../stylesheets/rds-image-gallery-tray.module.css";

class RdsImageGalleryTray extends Component {
  render() {
    const {
      images,
      sectionHeading,
      heading,
      backgroundColor,
      anchorTagValue,
      anchorTagClass,
      imagesPerRow = "4",
      termsText,
    } = this.props;
    const trayStyle = getTrayStyle(backgroundColor);
    const textStyle = getTextStyle(backgroundColor);

    const imagesPerRowClassMapping = {
      "2": "row justify-content-center",
      "3": "row row-cols-xs-1 row-cols-sm-2 row-cols-md-3",
      "4": "row row-cols-xs-1 row-cols-sm-2 row-cols-md-4",
      "5": "row row-cols-xs-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-5",
      "6": "row row-cols-xs-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-6",
      "4/3": "row",
    };

    const imageColClassMapping = clsx({
      [`col col-12 col-sm-6 col-md-5 col-lg-5 col-xl-4`]: imagesPerRow === "2",
    });

    const imageColVariableClassMapping = (index) => {
      if(imagesPerRow === "4/3") {
        return index < 4 ? "col col-12 col-sm-6 col-md-3" : "col col-12 col-sm-6 col-md-4"
      }
      return "";
    }

    // Assigning the class based on the number of images per row
    const imageClassMapping = clsx({
      [styles.image]: true,
      [styles.twoPerRow]: imagesPerRow === "2",
      [styles.threePerRow]: imagesPerRow === "3",
      [styles.fourPerRow]: imagesPerRow === "4",
      [styles.fivePerRow]: imagesPerRow === "5",
      [styles.sixPerRow]: imagesPerRow === "6",
    });

    const imageClassVariableMapping = (index) => {
      if(imagesPerRow === "4/3") {
        return index < 4 ? styles.fourPerRow : styles.threePerRow
      }
      return "";
    }

    // Function to render a div with class w-100 to clear the row
    const renderRowClear = (index, imagesPerRow) => {
      // If imagesPerRow === "2", and we have rendered 2 images in the row already
      if (imagesPerRow === "2"  && ((index - 1) % 2 === 0)) {
        // Add a div with class w-100 to clear the row
        return <div className="w-100"></div>;
      }
    };

    return (
      <div
        style={trayStyle}
        id={anchorTagValue}
        className={`${anchorTagClass} rds-wrapper`}
      >
        <div className={`container py-5`}>
          {sectionHeading && (
            <h2 className="rds-section-heading" style={textStyle}>
              {sectionHeading}
            </h2>
          )}
          {heading && (
            <h3
              className={`rds-heading ${styles.imagePadding}`}
              style={textStyle}
            >
              {heading}
            </h3>
          )}
          <div className={`${imagesPerRowClassMapping[imagesPerRow]} pb-4`}>
            {images.map((image, index) => (
              <React.Fragment key={index}>
                <div
                  key={index + "in"}
                  className={`${styles.imagePadding} ${imageColClassMapping} ${imageColVariableClassMapping(index)}`}
                >
                  {image.redirectToUrl ? (
                    <Link
                      to={image.redirectToUrl}
                      target={image?.target ? image?.target : "_self"}
                      rel={
                        image?.target === "_blank"
                          ? "noopener noreferrer"
                          : null
                      }
                    >
                      <img
                        src={image.image}
                        alt={
                          image?.imageAltText
                            ? image?.imageAltText
                            : image.heading
                        }
                        title={
                          image?.imageAltText
                            ? image?.imageAltText
                            : image.heading
                        }
                        className={`${imageClassMapping} ${imageClassVariableMapping(index)}`}
                      />
                    </Link>
                  ) : (
                    <img
                      src={image.image}
                      alt={
                        image?.imageAltText
                          ? image?.imageAltText
                          : image.heading
                      }
                      title={
                        image?.imageAltText
                          ? image?.imageAltText
                          : image.heading
                      }
                      className={`${imageClassMapping} ${imageClassVariableMapping(index)}`}
                    />
                  )}
                  <h3
                    className={`${styles.rdsImageGalleryTrayHeading} ${styles.subHeading}`}
                    style={textStyle}
                  >
                    {image.subHeading}
                  </h3>
                  <div className={styles.caption}>
                    {image?.caption && (
                      <h4
                        className={`${styles.rdsImageGalleryTraySubHeading} ${styles.captionHeading}`}
                        style={textStyle}
                      >
                        {image.caption}
                      </h4>
                    )}
                    {image?.description && (
                      <p
                        className={`${styles.rdsImageGalleryTraydescription}`}
                        style={textStyle}
                      >
                        {image.description}
                      </p>
                    )}
                  </div>
                </div>
                {renderRowClear(index, imagesPerRow)}
              </React.Fragment>
            ))}
          </div>
          {termsText && (
            <p className={`rds-font-body-2 text-center p-3 m-0`} style={textStyle}>
              {termsText}
            </p>
          )}
        </div>
      </div>
    );
  }
}

RdsImageGalleryTray.defaultProps = {
  imagesPerRow: "4",
};

export default RdsImageGalleryTray;
