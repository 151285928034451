import React from 'react'

const Electrical = ({ fillColor = "#ffffff" }) => {
    return (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3125 6.99512V9.33062" stroke={fillColor} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.6953 6.99512V9.33062" stroke={fillColor} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M18.7908 12.5414V9.80447C18.7909 9.63927 18.657 9.50537 18.4918 9.50537H9.51004C9.34484 9.50537 9.21094 9.63927 9.21094 9.80447V12.5414C9.21094 12.7066 9.34484 12.8405 9.51004 12.8405C9.67524 12.8405 9.80914 12.9744 9.80914 13.1396C9.80914 14.9717 11.2943 16.4568 13.1263 16.4568H14.8754C16.7075 16.4568 18.1926 14.9717 18.1926 13.1396C18.1926 12.9744 18.3265 12.8405 18.4917 12.8405C18.6569 12.8405 18.7908 12.7066 18.7908 12.5414ZM9.71094 12.366V10.0054H18.2908V12.366C17.9467 12.4551 17.6926 12.7677 17.6926 13.1396C17.6926 14.6955 16.4313 15.9568 14.8754 15.9568H13.1263C11.5704 15.9568 10.3091 14.6955 10.3091 13.1396C10.3091 12.7677 10.055 12.4551 9.71094 12.366Z" fill={fillColor} stroke={fillColor} />
            <path d="M14.6249 11.1265L13.409 12.505L14.6682 13.4674L13.3359 14.7045" stroke={fillColor} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.0035 16.4569V17.775C14.0035 20.2189 15.9846 22.2 18.4285 22.2C20.8723 22.2 22.8535 20.2189 22.8535 17.775V7.675C22.8535 5.23115 20.8723 3.25 18.4285 3.25H9.45625C7.0124 3.25 5.03125 5.23115 5.03125 7.675V9.5764" stroke={fillColor} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.64426 10.6912V10.6913V11.5611C5.64426 11.9008 5.36887 12.1762 5.02916 12.1762C4.68946 12.1762 4.41406 11.9008 4.41406 11.5611V10.6913C4.41406 10.3516 4.68945 10.0762 5.02916 10.0762C5.36894 10.0762 5.64429 10.3516 5.64426 10.6912Z" fill={fillColor} stroke={fillColor} />
        </svg>
    )
}

export default Electrical
