export const externalFranchiseDetails = [
  {
    id: "1122",
    franchiseName: "dayton",
    sourceFranchiseName: "Detmer & Sons",
    targetFranchiseName: "Dayton, OH",
  },
  {
    id: "1133",
    franchiseName: "richmond",
    sourceFranchiseName: "Dick Hill & Son",
    targetFranchiseName: "New Lebanon, OH",
  },
  {
    id: "1144",
    franchiseName: "hickory-and-denver-hvac",
    sourceFranchiseName: "Rye",
    targetFranchiseName: "Hickory & Denver, NC",
  },
  {
    id: "1155",
    franchiseName: "hickory-and-denver-hvac",
    sourceFranchiseName: "Canella",
    targetFranchiseName: "Hickory & Denver, NC",
  },
];

export const messageForExternalFranchise = (sourceFranchise) => {
  return `${sourceFranchise} is now Ace Hardware Home Services`;
};
