import { createSlice } from "@reduxjs/toolkit";

const loadFromLocalStorage = (storageName) => {
  try {
    const serializedState = localStorage.getItem(storageName);
    if (storageName === "SELECTED_PAGE_INDEX" && serializedState === null) {
      return 1;
    } else if (serializedState === null) {
      return [];
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return [];
  }
};

const saveToLocalStorage = (state, storageName) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(storageName, serializedState);
  } catch (e) {
    return [];
  }
};

const initialState = {
  selectedFilters: loadFromLocalStorage("SELECTED_FILTER"),
  selectedPageIndex: loadFromLocalStorage("SELECTED_PAGE_INDEX"),
};

const blogsFilterSlice = createSlice({
  name: "blogsFilter",
  initialState,
  reducers: {
    addSelectedFilter: (state, action) => {
      if (!state.selectedFilters.includes(action.payload)) {
        state.selectedFilters.push(action.payload);
        saveToLocalStorage(state.selectedFilters, "SELECTED_FILTER");

        state.selectedPageIndex = 1;
        saveToLocalStorage(state.selectedPageIndex, "SELECTED_PAGE_INDEX");
      }
    },
    removeSelectedFilter: (state, action) => {
      state.selectedFilters = state.selectedFilters.filter(
        (filter) => filter !== action.payload
      );
      saveToLocalStorage(state.selectedFilters, "SELECTED_FILTER");
      
      state.selectedPageIndex = 1;
      saveToLocalStorage(state.selectedPageIndex, "SELECTED_PAGE_INDEX");
    },
    clearAllFilter: (state, action) => {
      state.selectedFilters = [];
      saveToLocalStorage(state.selectedFilters, "SELECTED_FILTER");
      
      state.selectedPageIndex = 1;
      saveToLocalStorage(state.selectedPageIndex, "SELECTED_PAGE_INDEX");
    },
    addSelectedPageNumber: (state, action) => {
      state.selectedPageIndex = action.payload;
      saveToLocalStorage(state.selectedPageIndex, "SELECTED_PAGE_INDEX");
    },
  },
});
export const {
  addSelectedFilter,
  removeSelectedFilter,
  clearAllFilter,
  addSelectedPageNumber,
} = blogsFilterSlice.actions;
export default blogsFilterSlice.reducer;
