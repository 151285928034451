import React, { useEffect, useState, useRef, useContext } from "react";
import { Link, useLocation } from "react-router-dom";

import HeaderContext from "../../context/HeaderCtx";
import styles from "./BreadCrumb.module.css";

const BreadCrumb = () => {
  const bcRef = useRef(null);
  const location = useLocation();

  const [marginTopVal, setMarginTopVal] = useState("");
  const { headerHeight, isFranchiseRibbonPresent } = useContext(HeaderContext)
 

  useEffect(() => {
    if (bcRef.current) {
      if (headerHeight !== 0) setMarginTopVal(headerHeight);
    }
  }, [bcRef.current, headerHeight, isFranchiseRibbonPresent])

  const getSegmentName = (segment, index) => {
    if (segment === "service-details" || segment === "services") {
      return "";
    } else if (segment === "hvac") {
      return "HVAC";
    } else if (segment === "faq") {
      return "FAQ";
    } else if (index === 1 && pathSegments[0] === "offices") {
      return (
        "Ace Hardware Home Services " +
        segment
          .replaceAll("-", " ")
          .replaceAll(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
          .replaceAll(/\bhvac\b/gi, "HVAC")
      );
    } else {
      return segment
        .replaceAll("-", " ")
        .replaceAll(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
    }
  };
  
   /**
   * @function getSegmentLink 
   * @param {*} segment 
   * @param {*} pathSegments
   * @param {*} index
   */
  const getSegmentLink = (segment, pathSegments, index) => {
    if (segment === "offices") {
      return "/hvac/service-locations";
    } else {
      return "/" + pathSegments.slice(0, index + 1).join("/");
    }
  };

  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");

  if (!pathSegments?.length && !Boolean(isFranchiseRibbonPresent)) return null;

  return (
    <div
      className={`${location.pathname !== "/" ? styles.bcBlockSeparator : ""}`}
      ref={bcRef}
      id="breadcrumb"
      style={{ marginTop: marginTopVal }}
    >
      <div className="container">
        {location.pathname !== "/" && (
          <ul className={styles.bcContainer}>
            <>
              <li>
                <Link to="/">Ace Hardware Home Services</Link>
                <span className={styles.bcItemSeparator}>/</span>
              </li>

              {pathSegments.map((segment, index) => (
                <li key={index}>
                  {index === pathSegments.length - 1 ? (
                    <span>{getSegmentName(segment, index)}</span>
                  ) : (
                    <>
                      <Link to={getSegmentLink(segment, pathSegments, index)}>
                        {getSegmentName(segment, index)}
                      </Link>
                      {segment !== "service-details" && segment !== "services" &&(
                        <span className={styles.bcItemSeparator}>/</span>
                      )}
                    </>
                  )}
                </li>
              ))}
            </>
          </ul>
        )}
      </div>
    </div>
  );
};

export default BreadCrumb;
