import React from 'react'

import ImageContentCard from "../../Components/Cards/ImageContentCard/ImageContentCard";

const WhatIsAceHardwareServices = ({ contentData = {} }) => {
    return (
        <>
            <ImageContentCard
                image={contentData.image}
                header={contentData.header}
                title={contentData.title}
                titleDescription={contentData.titleDescription}
                linkText={contentData.linkText}
                linkColor={contentData.linkColor}
                showArrow={contentData.showArrow}
                altImgText={contentData.altImgText}
                linkUrl={contentData.linkUrl}
                tabId={contentData.tabId}
                reverse={contentData?.division === 'right' ? true : false}
                background={contentData.background}
            />
        </>
    )
}

export default WhatIsAceHardwareServices
