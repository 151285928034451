import React from 'react'

const Painting = ({ fillColor = "#ffffff" }) => {
    return (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.45056 6.55158C7.05796 7.98188 10.9697 7.88503 13.6725 6.95268C16.3753 6.02033 19.1654 6.68828 19.7208 7.78198V8.02263C19.7208 9.03398 18.9009 9.85388 17.8896 9.85388H7.22191C6.18056 9.85388 5.34876 8.98663 5.39226 7.94618L5.45056 6.55158Z" fill={fillColor} />
            <path d="M19.862 6.97363H21.1323C21.4394 6.97363 21.6884 7.22263 21.6884 7.52978V12.028C21.6884 12.3351 21.4394 12.5841 21.1323 12.5841H12.9936C12.6865 12.5841 12.4875 12.8331 12.4875 13.1403L12.4375 16.3164" stroke={fillColor} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M19.737 5.41364C19.737 4.48594 18.9849 3.73389 18.0572 3.73389H14.9288H10.1206H6.99225C6.06455 3.73389 5.3125 4.48594 5.3125 5.41364V8.17399C5.3125 9.10169 6.06455 9.85374 6.99225 9.85374H10.1206H14.9288H18.0572C18.9849 9.85374 19.737 9.10169 19.737 8.17399V5.41364Z" stroke={fillColor} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.1328 16.7946V22.1749C11.1328 22.2715 11.2112 22.3499 11.3078 22.3499H13.591C13.6876 22.3499 13.766 22.2715 13.766 22.1749V16.7946C13.766 16.698 13.6876 16.6196 13.591 16.6196H11.3078C11.2112 16.6197 11.1328 16.698 11.1328 16.7946Z" fill={fillColor} />
        </svg>
    )
}

export default Painting