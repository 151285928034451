import React from "react";
import { Link } from "react-router-dom";
import LinkArrow from "../SvgIconComponents/Arrows/LinkArrow";
import styles from "./LinkWithArrow.module.css";

const LinkWithArrow = (props) => {
  const {
    linkUrl,
    linkText,
    linkColor = "#222",
    linkTarget = "_self",
    showArrow = false,
    isIndividualLink,
  } = props;

  const linkBody = () => (
    <>
      <span>{linkText}</span>
      {showArrow && <LinkArrow linkColor={linkColor} />}
    </>
  );
  return (
    <>
      {!isIndividualLink ? (
        <Link
          to={linkUrl}
          target={linkTarget ? linkTarget : "_blank"}
          className={styles.linkWithArrow}
          style={{
            color: `${linkColor}`,
            borderBottom: `1px solid ${linkColor}`,
          }}
        >
          {linkBody()}
        </Link>
      ) : (
        <div className={styles.nonLink}>{linkBody()}</div>
      )}
    </>
  );
};

export default LinkWithArrow;
