import React, { useId } from "react";
import clsx from "clsx";
import "../stylesheets/rds-bootstrap.css";
import styles from "../stylesheets/rds-card.module.css";
import { Link } from "react-router-dom";
import "../stylesheets/rds-common.css";
import defaultImage from "../assets/BLOG HEADER_AHHS LOGO.jpg";

const CardTemplate = (props) => {
  const uniqueId = useId();
  const {
    footer,
    variant,
    type,
    title,
    subtitle,
    image,
    redirectUrl,
    categories,
    handleRedirect,
    onClickRedirectAction,
    isMobile,
  } = props;

  const isVertical = variant === "vertical";
  const isHorizontal = variant === "horizontal";
  const isSubTitle = Boolean(subtitle);
  const categoriesCount = Number(categories?.length);
  const visibleCategories = isMobile
    ? categories?.slice(0, 1)
    : categories?.slice(0, 3);
  const seeMoreCount = isMobile ? categoriesCount - 1 : categoriesCount - 3;
  const seeMoreCategories = isMobile
    ? categories?.slice(1)
    : categories?.slice(3);
  const validCategoryCount = isMobile
    ? categoriesCount > 1
      ? true
      : false
    : categoriesCount > 3
    ? true
    : false;

  const rdsCardClass = clsx({
    [styles.card]: true,
    [`flex-column`]: isVertical,
    [`flex-row align-items-center ${styles.horizontal}`]: isHorizontal,
    [styles.featured]: type === "featured",
  });

  const imageWrapperClass = clsx({
    [`col-12`]: isVertical,
    [`col-3 me-2`]: isHorizontal,
  });

  const contentWrapperClass = clsx({
    [`col-12`]: isVertical,
    [`col-9`]: isHorizontal,
  });

  const titleClass = clsx({
    [`rds-font-title-4`]: type === "featured",
    [`rds-font-product-title-1 ${styles.horizontal}`]: type !== "featured",
  });

  const imageClass = clsx({
    [`card-img-top mb-3`]: isVertical,
    [`me-3 align-self-stretch`]: isHorizontal,
  });

  const onClickAction = () => {
    onClickRedirectAction(redirectUrl);
  };

  const handleOnClick = (selectedCategory, redirectURL) => {
    handleRedirect?.(selectedCategory, redirectURL);
  };

  return (
    <div className={`card p-3 ${rdsCardClass}`}>
      <div className={`${imageWrapperClass} `}>
        {image ? (
          <img
            src={image}
            className={`${imageClass} ${styles.image}`}
            width="9"
            height="6"
            onClick={onClickAction}
          />
        ) : defaultImage ? (
          <img
            src={defaultImage}
            className={`${imageClass} ${styles.image}`}
            width="9"
            height="6"
            onClick={onClickAction}
          />
        ) : (
          ""
        )}
      </div>
      <div
        className={`card-body p-0 align-self-stretch ${contentWrapperClass}`}
      >
        <div className="row">
          <div className="col d-flex flex-wrap">
            {visibleCategories.map((category) => (
              <span
                key={category?.categoryName}
                className={`${styles.category} rds-font-label`}
                onClick={() =>
                  handleOnClick(category?.categoryName, category?.redirectUrl)
                }
              >
                {category?.categoryName}
              </span>
            ))}
            {validCategoryCount && (
              <input
                type="checkbox"
                id={uniqueId}
                className={styles.checkbox}
              />
            )}
            {validCategoryCount && (
              <label
                htmlFor={uniqueId}
                className={`${styles.more} rds-font-label`}
              >
                {`+ ${Number(seeMoreCount)} MORE`}{" "}
              </label>
            )}
            {seeMoreCategories.map((category) => (
              <span
                key={category.categoryName}
                className={`${styles.category} ${styles.extras} rds-font-label`}
                onClick={() =>
                  handleOnClick(category?.categoryName, category?.redirectUrl)
                }
              >
                {category.categoryName}
              </span>
            ))}
          </div>
        </div>
        <h5
          className={`${styles.title} pt-2 ${titleClass}`}
          tabIndex="0"
          data-toggle="tooltip"
          title={title}
          onClick={onClickAction}
        >
          {title}
        </h5>
        {subtitle && (
          <div className={`pt-2 rds-font-subtitle-1`}>{subtitle}</div>
        )}
        <div className={`rds-font-label pt-2 ${styles.footer}`}>{footer}</div>
      </div>
    </div>
  );
};

const RdsCard = ({ url, ...rest }) => {
  if (Boolean(url)) {
    return (
      <Link to={url} className={`text-decoration-none`}>
        <CardTemplate {...rest} />
      </Link>
    );
  }
  return <CardTemplate {...rest} />;
};

export default RdsCard;
