import { useLocation } from "react-router-dom";
import { externalFranchiseDetails } from "../constants/externalFranchiseDetails";

const useFranchiseNameForExternalFranchise = (queryParam) => {
  const franchiseIdQueryParam = new URLSearchParams(useLocation().search);
  const franchiseId = franchiseIdQueryParam.get(queryParam);

  return externalFranchiseName(franchiseId);
};

const externalFranchiseName = (externalFranchiseId) => {
  const franchiseDetail = externalFranchiseDetails.find(
    (franchiseId) => franchiseId.id === externalFranchiseId
  );
  return franchiseDetail?.franchiseName;
};

export default useFranchiseNameForExternalFranchise;
