import React from "react";
import style from "../stylesheets/rds-recent-blogs-tray.module.css";
import RdsCard from "./rds-card";

const RdsRecentBlogsTray = ({
  heading,
  cta,
  blogPosts,
  handleSeeAllBlogPosts,
  handleRedirectFromBLP,
  onClickRedirectAction,
}) => {
  const handleSeeAllBlogPostsRedirect = (url, tab) => {
    handleSeeAllBlogPosts?.(url, tab);
  };

  const handleRedirect = (selectedCategory, redirectURL) => {
    handleRedirectFromBLP?.({ selectedCategory, redirectURL });
  };

  return (
    <div className="container-fluid border-top pt-4 pb-4">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className={`${style.sectionTitle}`}>{heading}</h2>
          <span
            className={`${style.linkStyle}`}
            onClick={() => {
              handleSeeAllBlogPostsRedirect(
                cta?.redirectUrl,
                cta?.openInNewTab
              );
            }}
          >
            {cta?.text}
          </span>
        </div>
        <div className="row row-cols-1 row-cols-md-3">
          {blogPosts?.slice(0, 3)?.map((blogData, index) => {
            return (
              <div className={`${style.cardWrapper} mt-3`} key={index}>
                <RdsCard
                  variant={"vertical"}
                  title={blogData?.heading}
                  footer={blogData?.datePublished}
                  image={blogData?.featuredImage}
                  subtitle={blogData?.subHeading}
                  categories={blogData?.categories.slice(0, 1)}
                  handleRedirect={handleRedirect}
                  redirectUrl={blogData?.redirectUrl}
                  onClickRedirectAction={onClickRedirectAction}
                />
              </div>
            );
          })}
        </div>
        <div className="row row-cols-1 row-cols-md-2">
          {blogPosts?.slice(3, 5)?.map((blogData, index) => {
            return (
              <div className={`${style.cardWrapper}  mt-3`}  key={index}>
                <RdsCard
                  variant={"vertical"}
                  title={blogData?.heading}
                  footer={blogData?.datePublished}
                  image={blogData?.featuredImage}
                  subtitle={blogData?.subHeading}
                  categories={blogData?.categories.slice(0, 1)}
                  handleRedirect={handleRedirect}
                  redirectUrl={blogData?.redirectUrl}
                  onClickRedirectAction={onClickRedirectAction}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RdsRecentBlogsTray;
