import React from "react";

import MenuList from "../MenuList";
import styles from "./commonmenu.module.css";

const CommonMenu = ({ commonMenuData, handleMenuToggle }) => {
  return (
    <div className={styles.commonMenuContainer}>
      <ul className={styles.commonMenuList}>
        {commonMenuData.map((menu, index) => {
          return (
            <MenuList
              data={menu}
              handleMenuToggle={handleMenuToggle}
              hasSubMenu={false}
              key={index}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default CommonMenu;
