import { useEffect, useContext, useCallback } from 'react'
import { useLocation } from "react-router-dom";

import HeaderContext from "../../context/HeaderCtx";
import {
  scrollToSpecificPath,
  scrollToTop,
} from "../../Utilities/Utility";

const ScrollWrapper = ({ children }) => {
  const location = useLocation();
  //header height getting from the context
  const { headerHeight } = useContext(HeaderContext);

  const setStickyTabPosition = useCallback(() => {
    const stickyTab = document.querySelector(".sticky-tab");
    if (stickyTab) {
      let topValue = Math.floor(headerHeight);
      stickyTab.style.position = "sticky";
      stickyTab.style.top = `${topValue}px`;
    }
  }, [headerHeight]);

  useEffect(() => {
    const handleScroll = () => {
      setStickyTabPosition();
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setStickyTabPosition]);

  useEffect(() => {
    // Scroll behavior based on location change
    if (location?.hash) {
      const pathId = location?.hash?.split("#");
      if (pathId.length > 1) {
        scrollToSpecificPath(pathId[1]);
      } else {
        scrollToTop();
      }
    } else {
      scrollToTop();
    }
  }, [location]);

  return children;
};

export default ScrollWrapper;
