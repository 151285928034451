import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./faq.module.css";

import { fetchFranchiseFAQ } from "../../redux-toolkit/slice/franchiseFAQ/franchiseFAQSlice";
import DynamicTrays from "../../Components/DynamicTrays";
import ErrorPage from "../../Components/ErrorPage";
import useMetaDetails from "../../hooks/useMetaDetails";
import useDisplayMsgForExternalFranchise from "../../hooks/useDisplayMsgForExternalFranchise";
import FranchiseExternalMessageTray from "../../AceHomeServicesRds/src/components/rds-external-system-message";


const FranchiseFAQs = () => {
  const { data, isError, statusCode } = useSelector(
    (state) => state.franchiseFAQ
  );
  const externalFranchiseMessage =
    useDisplayMsgForExternalFranchise("officesource");
  const dispatch = useDispatch();
  const [isPageContainsError, setIsPageContainsError] = useState(false);

  useMetaDetails(data);

  useEffect(() => {
    const payLoad = "";
    dispatch(fetchFranchiseFAQ(payLoad));
  }, []);

  useEffect(() => {
    setIsPageContainsError(isError);
  }, [isError, statusCode]);

  return (
    <>
       {
        Boolean(externalFranchiseMessage) && <FranchiseExternalMessageTray externalFranchiseMessage={externalFranchiseMessage} />
      }
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className={styles.faqTitle}>
              <h1>{data?.pageHeading}</h1>
            </div>
          </div>
        </div>

        {data?.trays?.map((tray, index) => {
          return (
            <div className={styles.faqTray} key={index}>
              <DynamicTrays
                {...tray}
                key={index}
                externalFranchiseMessage={externalFranchiseMessage}
              />
            </div>
          );
        })}
        {isPageContainsError && <ErrorPage statusCode={statusCode} />}
      </div>
    </>
  );
};

export default FranchiseFAQs;
