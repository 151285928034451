import React from "react";
import PropTypes from "prop-types";
import RdsCategory from "./rds-category";
import "../stylesheets/rds-common.css";
import styles from "../stylesheets/rds-blog-filter.module.css";

const RdsBlogFilter = ({
  heading,
  blogCategories,
  selectedFilters,
  handleRemoveFilterClick,
  handleFilterClick,
}) => {
  const isSelected = (value) => selectedFilters.includes(value);

  const handleOnClick = (value) => {
    const category = value;

    isSelected(category)
      ? handleRemoveFilterClick?.(category)
      : handleFilterClick?.(category);
  };

  return (
    <div className={`${styles.wrapper} px-3`}>
      <div className="py-4">
        <div className="row my-2">
          <div className={`col`}>
            <h3 className={`${styles.title} rds-font-label`}>{heading}</h3>
          </div>
        </div>
        <div className="row">
          {blogCategories?.map((category) => (
            <div className={`col col-6`} key={category?.categoryName}>
              <RdsCategory
                label={category?.categoryName}
                handleClick={handleOnClick}
                isSelected={isSelected(category?.categoryName)}
                size="small"
                type="filter"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

RdsBlogFilter.propTypes = {
  heading: PropTypes.string,
  blogCategories: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    })
  ),
  selectedFilters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    })
  ),
};

export default RdsBlogFilter;
