import React from "react";
import { Link } from "react-router-dom";

import Rdsbtn from "../../rds-btn";

const ServiceRequestCTA = (props) => {
  const {
    recentFranchisePath = "",
    btnLabel="",
    handleMenuToggle = {}
  } = props;

  return (
    <>
      <Link
        to={`${recentFranchisePath}/book-service`}
        onClick={handleMenuToggle}
      >
        <Rdsbtn buttonClass="rds rds-btn primary w-100" text={btnLabel ?? "Book Service"} />
      </Link>
    </>
  )
};

export default ServiceRequestCTA;
