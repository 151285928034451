import React from "react";
import PropTypes from "prop-types";
import "../stylesheets/rds-bootstrap.css";
import "../stylesheets/rds-common.css";
import RdsCard from "./rds-card";

/**
 * @function RdsCorporateBlogsTray
 * @param {*} props
 * @returns //
 */

const RdsCorporateBlogsTray = ({
  blogPosts,
  handleRedirectFromBLP,
  onClickRedirectAction,
  isMobile,
}) => {
  const handleRedirect = (selectedCategory, redirectURL) => {
    handleRedirectFromBLP?.({ selectedCategory, redirectURL });
  };

  return (
    <div className="pt-2">
      {blogPosts?.map((blog, index) => (
        <div className="pb-3" key={index}>
          <RdsCard
            variant={"horizontal"}
            title={blog?.heading}
            footer={blog?.datePublished}
            subtitle={blog?.subtitle}
            image={blog?.featuredImage}
            categories={blog?.categories}
            handleRedirect={handleRedirect}
            redirectUrl={blog?.redirectUrl}
            onClickRedirectAction={onClickRedirectAction}
            isMobile={isMobile}
          />
        </div>
      ))}
    </div>
  );
};

RdsCorporateBlogsTray.propTypes = {
  blogPosts: PropTypes.array,
};

export default RdsCorporateBlogsTray;
