import React, { useContext } from "react";
import { defaultFooter } from "../../constants/footer";
import styles from "./Footer.module.css";
import FloatingCircle from "../FloatingCircle";
import HeaderContext from "../../context/HeaderCtx";
import RdsFooter from "../../AceHomeServicesRds/src/components/rds-footer-tray/rds-footer";

const Footer = () => {
  const { footerData, isFranchiseRibbonPresent } = useContext(HeaderContext);
  let footerProps = {};

  isFranchiseRibbonPresent
    ? (footerProps = { ...footerData, defaultFooter })
    : (footerProps = { defaultFooter });

  return (
    <div className={`${styles.footerContainer}`}>
      <FloatingCircle />
      <RdsFooter {...footerProps} />
    </div>
  );
};

export default Footer;
