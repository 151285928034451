import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { setMetaTitle, setMetaDescription } from "../../redux-toolkit/slice/metaSlice/metaSlice";
import { seoData } from '../../constants/Seo';

const SeoWrapper = ({ children }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const metaTitle = useSelector(state => state.meta.title);
    const metaDescription = useSelector(state => state.meta.description);

    useEffect(() => {
        const pageSeoData = seoData && seoData.filter((seoData) => seoData.url === location.pathname)[0];
        if (pageSeoData && Object.keys(pageSeoData)?.length) {
            const { pageTitle, pageDescription } = pageSeoData;
            dispatch(setMetaTitle(pageTitle));
            dispatch(setMetaDescription(pageDescription));
        }

    }, [location, dispatch]);

    useEffect(() => {
        const pageTitle = document.querySelector('title');
        if (pageTitle) {
            pageTitle.textContent = metaTitle;
        }
    }, [metaTitle]);

    useEffect(() => {
        const pageDescription = document.querySelector('meta[name="description"]');
        if (pageDescription) {
            pageDescription.setAttribute('content', metaDescription);
        }
    }, [metaDescription]);

    return children;
}

export default SeoWrapper;
