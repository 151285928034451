import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: '',
  description: '',
};

const metaSlice = createSlice({
  name: 'meta',
  initialState,
  reducers: {
    setMetaTitle(state, action) {
      state.title = action.payload;
    },
    setMetaDescription(state, action) {
      state.description = action.payload;
    },
  },
});

export const { setMetaTitle, setMetaDescription } = metaSlice.actions;
export default metaSlice.reducer;
