import AceHomeServicesLogo from "../assets/common/header/AHHSLogoNoTagline.png";

export const ACE_HOME_SERVICES = "Ace Hardware Home Services";

//Header Tray Logo on Header
export const logo = {
  path: AceHomeServicesLogo,
  alt: ACE_HOME_SERVICES,
  width: "119",
  height: "94",
  url: "/",
};

//Header Tray - menu data
export const rootMenu = [
  {
    menuText: "Home Services",
    redirectLink: "/",
    target: "_self",
    headerSubMenu: [
      {
        menuText: "Heating and Cooling",
        redirectLink: "/hvac",
        target: "_self",
        showIcon: true,
        iconName: "heating",
      },
      {
        menuText: "Plumbing",
        redirectLink: "/plumbing",
        target: "_self",
        showIcon: true,
        iconName: "plumbing",
      },
      {
        menuText: "Electrical",
        redirectLink: "/electrical",
        target: "_self",
        showIcon: true,
        iconName: "electrical",
      },
      {
        menuText: "Handyman",
        redirectLink: "https://acehandymanservices.com",
        target: "_blank",
        showIcon: true,
        iconName: "handyman",
      },
      {
        menuText: "Painting",
        redirectLink: "https://acehardwarepainting.com",
        target: "_blank",
        showIcon: true,
        iconName: "painting",
      },
    ],
  },
  {
    menuText: "About Us",
    redirectLink: "/about-us",
    target: "_self",
  },
];

//This menu ites shown on mobile navigation as common menu
export const footerLinks = [
  {
    menuText: "Our Company",
    redirectLink: "/",
    target: "_self",
  },
  {
    menuText: "Ace Hardware",
    redirectLink: "https://acehardware.com",
    target: "_blank",
  },
  {
    menuText: "Privacy Policy",
    redirectLink: "/privacy-policy",
    target: "_self",
  },
  {
    menuText: "Terms of Use",
    redirectLink: "/terms-and-conditions",
    target: "_self",
  },
  {
    menuText: "Sitemap",
    redirectLink: "/sitemap",
    target: "_self",
  },
];

//Service Request CTA data
export const serviceRequestData = {
  btnLabel: " Request Service",
};
