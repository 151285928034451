import React, { lazy, Suspense } from 'react';

import styles from './secondaynavbar.module.css';

const NavBar = lazy(() => import('../NavBar'));

const Loading = () => (<div className={styles.fsXs}>Loading...</div>);

const SecondaryNavBar = (props) => {
    return (
        <nav className={styles.secondayNavBarContainer} id="secondary-navbar">
            <div className="container">
                <Suspense fallback={<Loading />}>
                    <NavBar {...{ ...props, secNav: true }} />
                </Suspense>
            </div>
        </nav>
    )
}

export default SecondaryNavBar
