/* Seo Data for Page Titles and Meta Descriptions */
export const seoData = [
    {
        url: "/",
        pageTitle: 'Home Maintenance Services & Repair | Ace Hardware Home Services',
        pageDescription: 'Trusted provider of home preservation services including plumbing, electrical, and HVAC. Schedule an assessment with an Ace Home Services location near you.'
    },
    {
        url: "/service-locations",
        pageTitle: 'Service Locations | Ace Hardware Home Services',
        pageDescription: 'Find trusted licensed technicians with the Ace Home Services location nearest to you. Schedule an assessment and book your services.'

    },

    {
        url: "/about-us",
        pageTitle: 'About Us | Ace Hardware Home Services',
        pageDescription: 'We strive to make it easy for you to preserve and protect your home by providing trustworthy and professional services that save you time.'

    },

    {
        url: "/privacy-policy",
        pageTitle: 'Privacy Policy | Ace Hardware Home Services',
        pageDescription: 'Privacy Policy regarding information collection, use, and sharing.'

    },

    {
        url: "/terms-and-conditions",
        pageTitle: 'Terms and Conditions | Ace Hardware Home Services',
        pageDescription: ''

    },
    {
        url: "/sitemap",
        pageTitle: '',
        pageDescription: ''

    }
]
