import React from "react";
import LinkWithArrow from "../../LinkWithArrow/LinkWithArrow";
import styles from "./ImageContentCard.module.css";
import { useIsMobile } from "../../../Utilities/Utility";

const ImageContentCard = ({
  image,
  header,
  title,
  titleDescription,
  linkText,
  linkColor,
  linkTarget = "_self",
  showArrow,
  altImgText,
  tabId = "",
  linkUrl,
  reverse,
  background,
  backgroundImage = false,
  fiftyFifty = true, // Default to 50/50 variant
}) => {
  const isMobile = useIsMobile();

  const renderContent = (colClass, imgClass, imgStyle = {}) => (
    <div className={`container py-4`}>
      <div className={`row ${reverse ? "flex-row-reverse" : ""}`}>
        <div
          className={`${colClass} col-sm-12 align-self-center py-2 ${
            isMobile ? "mb-4" : ""
          }`}
        >
          <h2 className={styles.heading}>{header}</h2>
          <h3 className={styles.subHeading}>{title}</h3>
          <p>{titleDescription}</p>
          <div
            className={isMobile ? "d-flex justify-content-left my-4" : "my-4"}
          >
            {showArrow && (
              <LinkWithArrow
                linkText={linkText}
                linkColor={linkColor}
                linkTarget={linkTarget}
                showArrow={showArrow}
                linkUrl={linkUrl}
              />
            )}
          </div>
        </div>
        <div className={`${imgClass} col-sm-12 d-flex justify-content-center`}>
          <img src={image} alt={altImgText} style={imgStyle} />
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={backgroundImage ? styles.bgImage : ""}
      style={{ background: !backgroundImage ? background : "" }}
    >
      {fiftyFifty
        ? renderContent("col-md-6", "col-md-6", {
            maxWidth: "80%",
            height: "auto",
          })
        : renderContent("col-md-8", "col-md-4", { width: "100%" })}
    </div>
  );
};

export default ImageContentCard;
