export const TEXTAREA = "TEXTAREA";

export const BACKGROUND_STYLES = {
  Gray: `url(${process.env.PUBLIC_URL}/graybackgroundColor.png)`,
  Triangle: (backgroundColor) =>
    `url(${process.env.PUBLIC_URL}/${backgroundColor}.png)`,
};

export const ACE_BUSINESS_NAME = 'Ace Hardware Home Services';
export const SCHEDULE_NOW = 'or call to Schedule your service now';
