import React from "react";

import styles from "./CircleWithNumber.module.css";

const CircleWithNumber = ({ text = "", width = "5rem", height = "5rem", textSize = "3rem" }) => {

  const inlineStyle = {
    display: "flex",
    backgroundColor: "#d5202e",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    padding: "12px 16px",
  }
  return (
    <div className={"rounded-circle"} style={{ ...inlineStyle, width: width, height: height, fontSize: textSize }}>{text}</div>
  );
};

export default CircleWithNumber;
