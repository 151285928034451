import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchLineOfBusinessServiceLocations } from "../../redux-toolkit/slice/lineOfBusinessServiceLocations/lineOfBusinessServiceLocationsSlice";
import DynamicTrays from "../../Components/DynamicTrays";
import ErrorPage from "../../Components/ErrorPage";
import useMetaDetails from "../../hooks/useMetaDetails";
import useDisplayMsgForExternalFranchise from "../../hooks/useDisplayMsgForExternalFranchise";
import HeaderContext from "../../context/HeaderCtx";

const LineOfBusinessServiceLocations = () => {
  const { lobType } = useParams();
  const {
    data = {},
    isError,
    statusCode,
  } = useSelector((state) => state.lineOfBusinessServiceLocations);
  const externalFranchiseMessage =
    useDisplayMsgForExternalFranchise("officesource");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPageContainsError, setIsPageContainsError] = useState(false);
  const { headerHeight } = useContext(HeaderContext);

  useMetaDetails(data);

  const stateRefs = useRef({})

  const handleScrollTo = (stateName) => {
    const element = stateRefs.current[stateName];
    if (element) {
      const elementTop = element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementTop - headerHeight - 20;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const handleOnClickRedirectAction = (event, redirectUrl) => {
    event.preventDefault();
    navigate(`${redirectUrl}`);
  }

  useEffect(() => {
    const payLoad = `?lobtype=${lobType}/service-locations`;
    dispatch(fetchLineOfBusinessServiceLocations(payLoad));
  }, []);

  useEffect(() => {
    setIsPageContainsError(isError);
  }, [isError, statusCode]);

  return (
    <>
      {data?.trays?.map((tray, index) => {
        return (
          <DynamicTrays
            {...tray}
            key={index}
            externalFranchiseMessage={externalFranchiseMessage}
            handleOnClickRedirectAction={handleOnClickRedirectAction}
            handleScrollTo={handleScrollTo}
            stateRefs={stateRefs}
          />
        );
      })}
      {isPageContainsError && <ErrorPage statusCode={statusCode} />}
    </>
  );
};

export default LineOfBusinessServiceLocations;
