import React from "react";
import PropTypes from "prop-types";
import ExternalSystemMessageTray from "./rds-external-system-message";
import RdsTextLink from "./rds-textlink";
import styles from "../stylesheets/rds-franchise-home-tile-card-tray.module.css";
import Rating from "react-rating";
import starEmpty from "../assets/star-empty.png";
import starRed from "../assets/star-red.png";
import phoneIcon from "../assets/phone-icon.png";
import { dataPush } from "../GAnalytics";
import { formatPhoneNumber } from "../utility";
import { PHONE_NUMBER_CLICK } from "../gaTrack";

export default function RdsFranchiseHomeTileCardTray({
  anchorTagValue,
  heading,
  subHeading,
  franchiseData,
  franchiseLicense,
  franchiseRating,
  franchiseTotalReviewCount,
  franchiseViewAllReviewsLink,
  franchiseWriteReviewLink,
  franchisePhoneNumber,
  externalFranchiseMessage,
}) {
  const franchiseDetails = franchiseData?.franchiseDetails;
  const location = window.location.href;

  const handleWindowTracker = (e, franchiseDetails, location) => {
    e.stopPropagation();

    // Google Analytics event tracking code
    dataPush(
      PHONE_NUMBER_CLICK,
      franchiseDetails?.headerFranchiseName,
      franchiseDetails?.headerFranchiseAddress,
      location
    );
  };

  const renderFranchiseInfo = () => (
    <>
      <div className={`mb-2 ${styles.heading}`}>{heading}</div>
      <div className={`mb-2 ${styles.subHeading}`}>{subHeading}</div>
      <div
        className={`mb-3 ${styles.license}`}
      >{`License ${franchiseLicense}`}</div>
    </>
  );

  const renderRatingStars = (rating) => (
    <Rating
      placeholderRating={rating}
      emptySymbol={
        <img
          alt="Empty Rating"
          src={starEmpty}
          className={`rds-rating-icon ${styles.ratingStar}`}
        />
      }
      placeholderSymbol={
        <img
          alt="Star Rating"
          src={starRed}
          className={`rds-rating-icon ${styles.ratingStar}`}
        />
      }
      readonly={true}
    />
  );

  const renderRatingsAndReviewsInfo = () => (
    <>
      <div className="d-flex mb-2">
        <div className={`${styles.franchiseRating}`}>{franchiseRating}</div>
        <div className={`mx-2 ${styles.ratingStarWrapper}`}>
          {renderRatingStars(franchiseRating)}
        </div>
        <div
          className={`${styles.reviewCount}`}
        >{`(${franchiseTotalReviewCount})`}</div>

        {/* Desktop Text Links */}
        <div className="d-none d-md-flex">
          <div className="mx-2">
            <RdsTextLink
              linkClass={`rds rds-review-link secondary small ${styles.reviewLink}`}
              text={"See all reviews"}
              link={franchiseViewAllReviewsLink}
              target="_blank"
            />
          </div>
          {"|"}
          <div className="mx-2">
            <RdsTextLink
              linkClass={`rds rds-review-link secondary small ${styles.reviewLink}`}
              text={"Write a review"}
              link={franchiseWriteReviewLink}
              target="_blank"
            />
          </div>
        </div>
      </div>

      {/* Mobile Text Links */}
      <div className="d-flex flex-row gap-2 d-md-none mb-2">
        <div className="mx-2">
          <RdsTextLink
            linkClass={`rds rds-review-link secondary small ${styles.reviewLink}`}
            text={"See all reviews"}
            link={franchiseViewAllReviewsLink}
            target="_blank"
          />
        </div>
        <div>
          <RdsTextLink
            linkClass={`rds rds-review-link secondary small ${styles.reviewLink}`}
            text={"Write a review"}
            link={franchiseWriteReviewLink}
            target="_blank"
          />
        </div>
      </div>
    </>
  );

  const renderBookServiceInfo = () => (
    <>
      <div className={`mb-3 ${styles.bookServiceText}`}>
        {"Need service now?"}
        <br />
        {"Call to book your service, it’s faster:"}
      </div>
      <div className={styles.phoneNumberWrapper}>
        <img
          alt="PhoneIcon"
          src={phoneIcon}
          className={`mx-2 mb-1 ${styles.phoneIcon}`}
        />
        <a
          className={styles.phoneNumber}
          href={`tel:${franchisePhoneNumber}`}
          onClick={(e) => handleWindowTracker(e, franchiseDetails, location)}
        >
          {formatPhoneNumber(franchisePhoneNumber)}
        </a>
      </div>
    </>
  );

  const renderTray = () => (
    <div className="row">
      <div className="col-12 col-md-8 col-lg-6">
        {renderFranchiseInfo()}
        {renderRatingsAndReviewsInfo()}
      </div>
      <div className="col-12 col-md-4 col-lg-6 d-flex flex-column align-items-start align-items-md-end">
        {renderBookServiceInfo()}
      </div>
    </div>
  );

  return (
    <div
      className={styles.franchiseHomeTileCardTrayWrapper}
      id={anchorTagValue}
    >
      <div className="container">
        <div className="row">{renderTray()}</div>
      </div>
      {externalFranchiseMessage && (
        <ExternalSystemMessageTray
          externalFranchiseMessage={externalFranchiseMessage}
        />
      )}
    </div>
  );
}

RdsFranchiseHomeTileCardTray.propTypes = {
  anchorTagValue: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  franchiseData: PropTypes.shape({
    franchiseDetails: PropTypes.shape({
      headerFranchiseName: PropTypes.string,
      headerFranchiseAddress: PropTypes.string,
    }),
  }).isRequired,
  franchiseLicense: PropTypes.string.isRequired,
  franchiseRating: PropTypes.number.isRequired,
  franchiseTotalReviewCount: PropTypes.number.isRequired,
  franchiseViewAllReviewsLink: PropTypes.string.isRequired,
  franchiseWriteReviewLink: PropTypes.string.isRequired,
  franchisePhoneNumber: PropTypes.string.isRequired,
  externalFranchiseMessage: PropTypes.string,
};
