import React, { useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import DropDown from "../DropDown";
import FaChevronDown from "../../SvgIconComponents/Arrows/FaChevronDown";
import styles from "./menuitems.module.css";
import Heating from "../../SvgIconComponents/Header/Heating";
import Plumbing from "../../SvgIconComponents/Header/Plumbing";
import Electrical from "../../SvgIconComponents/Header/Electrical";
import Handyman from "../../SvgIconComponents/Header/Handyman";
import Painting from "../../SvgIconComponents/Header/Painting";
import "../../../stylesheets/rds-bootstrap.css";

//for mapping the icons for L0/L1 menus
const IconDetails = {
  heating: Heating,
  electrical: Electrical,
  handyman: Handyman,
  painting: Painting,
  plumbing: Plumbing
}

export const MenuItemLink = ({ children, ...rest }) => {
  const {
    redirectLink,
    target,
    handleMenuToggle,
    linkClasses,
    showIcon
  } = rest;

  return (
    <Link
      to={redirectLink}
      target={target}
      rel={target === "_blank" ? "noopener noreferrer" : null}
      className={`${linkClasses} ${showIcon ? 'd-flex align-items-center' : ''}`}
      onClick={handleMenuToggle}
    >
      {children}
    </Link>
  )
};

export const MenuItemIcon = ({ iconFlag = false, iconName, secNav }) => {
  const SelectedComponent = IconDetails[iconName];
  const iconColor = secNav ? "#ffffff" : "#222222";

  return (
    <div className="me-2">
      {iconFlag && SelectedComponent ? <SelectedComponent fillColor={iconColor} /> : null}
    </div>
  )
}

const MenuItems = (props) => {
  const {
    items = {},
    depthLevel = 0,
    handleMenuToggle = {},
    isSecondaryNavigationActive = false,
    secNav = false,
    index = 0
  } = props;

  const location = useLocation();
  const menuRef = useRef(null);
  const [dropdown, setDropdown] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ leftPos: "auto", mHeight: "auto", parentTop: "0" });

  const handleMouseOver = (e) => {
    e.preventDefault();

    const menuRect = menuRef?.current?.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const menuWidth = 320;

    if (depthLevel > 0) {
      if ((menuRect.right + menuWidth) > windowWidth) {
        setMenuPosition(prevPos => ({ ...prevPos, leftPos:  -(menuWidth) }))
      } else setMenuPosition(prevPos => ({ ...prevPos, leftPos: '295px' }))
    }
    else setMenuPosition(prevPos => ({ ...prevPos, leftPos: "0" }))

    setDropdown(true);
  };

  const handleMouseOut = (e) => {
    e.preventDefault();
    setDropdown(false);
  };

  const handleMouseEnter = (e) => {
    e.preventDefault();

    if (!items?.headerSubMenu) {
      return
    }
    //Below currentTarget element, its parent element, grandparent element
    //need in the case of L2 menu item. L2 menu should position at the parent L1
    //level top position and L2 should have equal height of the parent.
    const target = e.currentTarget;
    const parentEl = target?.parentElement;
    const grandparentEl = parentEl?.parentElement;
    const gpHeight = grandparentEl?.offsetHeight + (34.3); //L1 menu height

    //calculating the top value of the Parent element (L1) for L2 to position.
    const parentTop = parentEl?.getBoundingClientRect().top;

    if (depthLevel !== 0) setMenuPosition(prevPos => ({ ...prevPos, mHeight: gpHeight, parentTop }))
  }

  return (
    <>
      <li className={styles.menuItems}>
        {items?.headerSubMenu && items?.headerSubMenu?.length ? (
          <>
            <div
              aria-haspopup="menu"
              className={secNav ? styles.dropDownItemSecNav : styles.dropDownItem}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              onMouseEnter={handleMouseEnter}
              onClick={handleMenuToggle}
              aria-expanded={dropdown ? "true" : "false"}
              style={{ paddingLeft: (secNav && index === 0) ? "0" : "" }}
              ref={menuRef}
            >
              <MenuItemLink {
                ...{
                  ...items,
                  handleMenuToggle,
                  linkClasses: secNav ? styles.dropDownItemParentSecNav : styles.dropDownItemParent
                }}>
                <MenuItemIcon {
                  ...{
                    iconFlag: items?.showIcon,
                    iconName: items?.iconName,
                    secNav
                  }}
                />
                {items.menuText}
              </MenuItemLink>
              <FaChevronDown
                fillColor={secNav ? "#ffffff" : "#121212"}
                rotate={
                  items?.headerSubMenu && depthLevel > 0
                    ? "-90"
                    : depthLevel === 0 && dropdown
                      ? "-180"
                      : "0"
                }
              />
              <DropDown
                {
                ...{
                  parentMenuName: items?.menuText,
                  subMenuItems: items?.headerSubMenu,
                  dropdown,
                  depthLevel,
                  position: {
                    leftPos: menuPosition?.leftPos,
                    topPos: depthLevel > 0 ? "0" : "46",
                    parentTop: menuPosition?.parentTop,
                    height: menuPosition?.mHeight,
                  },
                  handleMenuToggle,
                  isSecondaryNavigationActive
                }
                }
              />
            </div>
          </>
        ) : (
          <MenuItemLink {
            ...{
              ...items,
              handleMenuToggle,
              linkClasses: `${secNav ? styles.menuItemSecNav : styles.menuItem} ${location.pathname === items?.redirectLink ? styles.activeNav : ""}`
            }}>
            <MenuItemIcon {
              ...{
                iconFlag: items?.showIcon,
                iconName: items?.iconName
              }}
            />
            {items.menuText}
          </MenuItemLink>
        )}
      </li>
    </>
  );
};

export default MenuItems;
