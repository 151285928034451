import React from "react";
import PropTypes from "prop-types";
import styles from "../stylesheets/rds-year-list.module.css";
import "../stylesheets/rds-bootstrap.css";
import "../stylesheets/rds-common.css"

/**
 * @function RdsYearList
 * @param {*} props 
 * @returns Year List Filter component 
 */

const RdsYearList = (props) => {
    const {
        blogPublishedYearList,
        heading,
        handleFilterClick,
        handleRemoveFilterClick,
        selectedFilters,
    } = props;

    /**
     * @function handleClick
     * @param {*} year 
     * Used to handle the Click event for the filter once filter is selected following props can be update 
     * selectedFilters
     */
    const handleClick = (year) => {
        if (isSelected(year)) {
            handleRemoveFilterClick(year);
        } else {
            handleFilterClick(year);
        }
    };

    /**
     * @function isSelected
     * @param {*} year 
     * @returns if true year filter is selected  or not 
     */
    const isSelected = (year) => selectedFilters.includes(year);

    return (
        <div className={`${styles.wrapper} p-3`} >
            <div className="rds-font-label text-uppercase">{heading}</div>
            <div className={styles.yearsList}>
                {blogPublishedYearList?.map((year) => (
                    <div
                        className={styles.year}
                        key={year}
                        onClick={() => handleClick(year)
                        }
                    >
                        <div className={styles.iconArea}>
                            {isSelected(year) && (
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" fill="currentColor" class="bi bi-check" >
                                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                                </svg>
                            )}
                        </div>
                        <div>{year}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

RdsYearList.propTypes = {
    blogPublishedYearList: PropTypes.array,
    heading: PropTypes.string,
    handleFilterClick: PropTypes.func,
    handleRemoveFilterClick: PropTypes.func,
    selectedFilters: PropTypes.array,
};

export default RdsYearList;
