import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

import ScrollWrapper from "./providers/ScrollWrapper";
import SeoWrapper from "./providers/SeoWrapper";
import ErrorBoundary from "./providers/ErrorBoundary";
import CanonicalTagWrapper from "./providers/CanonicalTagWrapper";
import { HeaderCtxProvider } from "./context/HeaderCtx";
import RootRouter from "./routes";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import BreadCrumb from "./Components/BreadCrumb/BreadCrumb";
import FloatingCircle from "./Components/FloatingCircle";
import { store } from "./redux-toolkit/store";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

const Spinner = () => {
  return <p>Loading.....</p>;
};

function App() {
  return (
    <React.Suspense fallback={<Spinner />}>
      <ErrorBoundary>
        <Provider store={store}>
          <Router>
            <HeaderCtxProvider>
              <CanonicalTagWrapper>
                <Header />
                <ScrollWrapper>
                  <SeoWrapper>
                    <BreadCrumb />
                    <RootRouter />
                  </SeoWrapper>
                </ScrollWrapper>
                <FloatingCircle />
                <Footer />
              </CanonicalTagWrapper>
            </HeaderCtxProvider>
          </Router>
        </Provider>
      </ErrorBoundary>
    </React.Suspense>
  );
}

createRoot(document.getElementById("root")).render(<App />);
