import React from "react";
import styles from "./errorPage.module.css";
import { logo } from "../../constants/header";
import Rdsbtn from "../../AceHomeServicesRds/src/components/rds-btn";
import { Link } from "react-router-dom";
import { ErrorConfiguration } from "../../constants/errorPage";

const ErrorPage = (data) => {
  return (
    <div className={`${styles.errorContainer}`}>
      <img
        src={logo.path}
        alt={logo.alt}
        className={styles.image}
      />
      <h2 className={`${styles.headerTextStyle}`}>{data?.statusCode == "404" ? ErrorConfiguration.headerText404 : ErrorConfiguration.headerText500}</h2>
      <p className={`${styles.detailTextStyle}`}>{data?.statusCode == "404" ? ErrorConfiguration.detailText404 : ErrorConfiguration.detailText500}</p>
      <span className={`${styles.header2TextStyle}`}>{ErrorConfiguration.thankText}</span>
      <Link
        to={logo.url}
      >
        <Rdsbtn
          text={ErrorConfiguration.buttonText}
          buttonClass="rds rds-btn primary"
        />
      </Link>
      <span className={`${styles.header2TextStyle}`}>You can reach our online support team by email at <a style={{ text: "red" }} href="mailto:carecenter@acehardware.com">carecenter@acehardware.com</a> or by phone at 1-888-827-4223.</span>
    </div>
  );
};

export default ErrorPage;
