import React from 'react'

const HamBurgerMenu = ({ fillColor = "#000" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 25" fill="none">
            <path d="M1 5.07994C1 4.42927 1.5279 3.90137 2.17857 3.90137H21.8214C22.4746 3.90137 23 4.42927 23 5.07994C23 5.73306 22.4746 6.25851 21.8214 6.25851H2.17857C1.5279 6.25851 1 5.73306 1 5.07994ZM1 12.9371C1 12.284 1.5279 11.7585 2.17857 11.7585H21.8214C22.4746 11.7585 23 12.284 23 12.9371C23 13.5902 22.4746 14.1157 21.8214 14.1157H2.17857C1.5279 14.1157 1 13.5902 1 12.9371ZM21.8214 21.9728H2.17857C1.5279 21.9728 1 21.4473 1 20.7942C1 20.1411 1.5279 19.6157 2.17857 19.6157H21.8214C22.4746 19.6157 23 20.1411 23 20.7942C23 21.4473 22.4746 21.9728 21.8214 21.9728Z" fill={fillColor} />
        </svg>
    )
}

export default HamBurgerMenu
