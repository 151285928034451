import React from "react";
import PropTypes from "prop-types";
import "../stylesheets/rds-bootstrap.css";
import "../stylesheets/rds-common.css";
import RdsCard from "./rds-card";

/**
 * @function RdsRecentBlogPostTray
 * @param {*} props
 * @returns //
 */

const RdsRecentBlogPostTray = (props) => {
  const { heading, blogPosts, handleRedirectFromBLP, onClickRedirectAction } =
    props;

  const handleRedirect = (selectedCategory, redirectURL) => {
    handleRedirectFromBLP?.({ selectedCategory, redirectURL });
  };

  return (
    <div className="p-3">
      <div className="rds-font-label title mb-3">{heading}</div>
      {blogPosts?.map((blog, index) => (
        <div className="pb-2" key={index}>
          <RdsCard
            variant={"horizontal"}
            title={blog?.heading}
            footer={blog?.datePublished}
            subtitle={blog?.subtitle}
            image={blog?.featuredImage}
            categories={blog?.categories.slice(0, 1)}
            handleRedirect={handleRedirect}
            redirectUrl={blog?.redirectUrl}
            onClickRedirectAction={onClickRedirectAction}
          />
        </div>
      ))}
    </div>
  );
};

RdsRecentBlogPostTray.propTypes = {
  blogPosts: PropTypes.array,
  heading: PropTypes.string,
  onClickRedirectAction: PropTypes.func,
};

export default RdsRecentBlogPostTray;
