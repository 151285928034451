import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import NetworkManager from "../../networkManager/networkConfig";

export const fetchFranchiseFAQ = createAsyncThunk(
  "fanchisefaq/fetchData",
  async (payload) => await NetworkManager.franchiseFAQ(payload)
);

const initialState = {
  data: null,
  isLoading: null,
  isError: null,
  statusCode: 0,
};

const franchiseFAQSlice = createSlice({
  name: "franchiseFAQ",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFranchiseFAQ.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.statusCode = 0;
      })
      .addCase(fetchFranchiseFAQ.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
        state.isError = action.payload.status == "200" ? false : true;
        state.statusCode = action.payload.status;
      })
      .addCase(fetchFranchiseFAQ.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
        state.statusCode = 0;
      });
  },
});

export default franchiseFAQSlice.reducer;