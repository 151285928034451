import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

const GoogleTagManager = ({ gtmHeaderId, gtmFooterId }) => {
  const location = useLocation();
  useEffect(() => {
    // Function to inject GTM script
    const injectGtmHeadScript = (id) => {
      const script = document.createElement("script");
      script.id = `gtm-script-${id}`;
      script.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${id}');
      `;
      document.head.appendChild(script);
    };

    const injectGtmBodyScript = (id) => {
      const noScript = document.createElement("noscript");
      noScript.id = `gtm-noscript-${id}`;
      noScript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
      document.body.appendChild(noScript);
    };

    // Check if the current route matches the specified patterns
    const isOfficeRoute = location.pathname.includes("/offices/");
    const isMaintenanceRoute = location.pathname.includes("/maintenance");

    // Inject the GTM script if the route matches
    if ((isOfficeRoute || isMaintenanceRoute) && location.search === "") {
      if (gtmHeaderId) {
        injectGtmHeadScript(gtmHeaderId);
      }
      if (gtmFooterId) {
        injectGtmBodyScript(gtmFooterId);
      }
    }

    // Clean up function to remove the script when the component unmounts
    return () => {
      const removeElementsByIdPrefix = (prefix) => {
        const elements = document.querySelectorAll(`[id^="${prefix}"]`);
        elements.forEach((element) => {
          element.remove();
        });
      };

      if (gtmHeaderId) {
        removeElementsByIdPrefix(`gtm-script-${gtmHeaderId}`);

        const script = document.querySelector(
          `script[src*="gtm.js?id=${gtmHeaderId}"]`
        );
        if (script) {
          script.remove();
        }
      }

      if (gtmFooterId) {
        removeElementsByIdPrefix(`gtm-noscript-${gtmFooterId}`);

        const noScript = document.querySelector(
          `script[src*="ns.html?id=${gtmFooterId}"]`
        );
        if (noScript) {
          noScript.remove();
        }
      }
    };
  }, [gtmHeaderId, gtmFooterId]);

  return null;
};

export default GoogleTagManager;
