import React from "react";
import "../stylesheets/rds-common.css";
import styles from "../stylesheets/rds-image-copy-tray.module.css";
import clsx from "clsx";
import Rdsbtn from "./rds-btn";
import { getTrayStyle, getTextStyle, getBootstrapColSplit } from "../utility";
import { Markup } from "interweave";
import PropTypes from "prop-types";
import "../stylesheets/rds-bootstrap.css";

class RdsImageCopyTray extends React.Component {
  onClickHandler = (e) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  render() {
    const {
      anchorTagValue,
      backgroundColor = "white",
      imageAlignment = "LEFT",
      trayDimension = "70-30",
      sectionHeading,
      heading,
      subHeading,
      summary,
      imageUrl,
      imageAltText,
      renderAction,
      cta,
      franchiseData,
    } = this.props;

    // Destruct out the start and end Bootstrap cols
    const { startCol, endCol } = getBootstrapColSplit(trayDimension);
    // Conditional class for if the columns should reverse
    const containerRowClass = clsx({
      [`flex-row-reverse`]: imageAlignment?.toUpperCase() === "LEFT",
    });

    const trayStyle = getTrayStyle(backgroundColor);
    let textStyle = getTextStyle(backgroundColor);
    const headerFranchisePhone =
      franchiseData?.franchiseDetails?.headerFranchisePhone;
    const renderRdsBtn = !headerFranchisePhone ? null : (
      <Rdsbtn
        tel={headerFranchisePhone}
        text={cta?.text}
        buttonClass="rds rds-btn primary phone-icon white-phone"
      />
    );

    return (
      <>
        <div className="rds-wrapper" style={trayStyle} id={anchorTagValue}>
          <div className="container">
            <div className={`row ${styles.mainWrapper} ${containerRowClass}`}>
              <div className={`col-12 col-md-${startCol}`}>
                <div className="py-3">
                  {sectionHeading && (
                    <h2 className={`rds-section-heading ${styles.textContent} text-start`} style={textStyle}>
                      {sectionHeading}
                    </h2>
                  )}
                  {heading && (
                    <h3 className={`rds-font-title-3 ${styles.textContent}`} style={textStyle}>
                      {heading}
                    </h3>
                  )}
                  {subHeading && (
                    <h4 className={`rds-font-title-4 ${styles.textContent}`} style={textStyle}>
                      {subHeading}
                    </h4>
                  )}
                  {summary && (
                    <p className={`rds-font-body-1 ${styles.anchorText}`} style={textStyle}>
                      <Markup content={summary} />
                    </p>
                  )}
                  <div className={`mt-4 ${styles.textContent}`}>{renderAction}</div>
                  {cta && cta?.text && cta.style === "Phone_Number" && (
                    <div className="mb-4">{renderRdsBtn}</div>
                  )}
                </div>
              </div>
              <div className={`col-12 col-md-${endCol}`}>
                <div>
                  <img
                    src={imageUrl}
                    alt={imageAltText || sectionHeading}
                    title={imageAltText || sectionHeading}
                    className={`${styles.image} mb-3`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

RdsImageCopyTray.propTypes = {
  backgroundColor: PropTypes.string,
  imageAlignment: PropTypes.string,
  trayDimension: PropTypes.string,
  sectionHeading: PropTypes.string,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  summary: PropTypes.string,
  imageUrl: PropTypes.string,
  imageAltText: PropTypes.string,
};
export default RdsImageCopyTray;
