import React from "react";
import "../stylesheets/rds-input-text.css";
import {
  formInputValidations,
  formatPhoneNumber,
  getDateInputISO,
} from "../utility";

class RdsTextInput extends React.Component {
  componentDidMount() {
    if (this.props.autoFocus && this.props.forwardedRef) {
      this.props.forwardedRef.current.focus();
      this.props.forwardedRef.current.focus();
      this.props.forwardedRef.current.autofocus = true;
    }

    let isIOS = false;

    if (
      /CriOS/i.test(navigator.userAgent) &&
      /iphone|ipod|ipad/i.test(navigator.userAgent)
    ) {
      isIOS = true;
    }

    var me = this;

    //Only for Chrome IOS.
    if (
      isIOS &&
      this.props.forwardedRef &&
      this.props.forwardedRef.current &&
      this.props.forwardedRef.current.addEventListener
    ) {
      let transitionEventHandler = (e) => {
        setTimeout(() => {
          var input = document.getElementById(me.props.id);
          let target = {
            name: me.props.name,
            value: input?.value || "",
            inputElFromAutoFill: input,
          };
          let event = {
            target: target,
            currentTarget: target,
          };
          if (me.props.onChange) {
            me.props.onChange(event);
          } else if (me.props.onKeyUp) {
            me.props.onKeyUp(event);
          }
        }, 400);
      };
      // let events = [ "webkitAnimationStart", "webkitAnimationStart",  "animationstart", "animationend", "transitionstart", "transitionend"];
      this.props.forwardedRef.current.addEventListener("transitionend", (e) => {
        var input = document.getElementById(me.props.id);
        if (input != document.activeElement || input?.value)
          transitionEventHandler(e);
      });
    }

    setTimeout(() => {
      var input = document?.getElementById(me?.props?.id);
      if (input?.matches(":-webkit-autofill")) {
        document
          .getElementById(me.props.id + "-label-wrap")
          ?.classList?.add("input-autofilled");
      } else if (input?.matches(":-webkit-autofill")) {
        document
          .getElementById(me.props.id + "-label-wrap")
          ?.classList?.add("input-autofilled");
      }
    }, 500);
  }

  clearInput(e) {
    var input = document.getElementById(this.props.id);
    input.value = "";
    input.focus();
    if (this.props.onClear) {
      e.target = input;
      this.props.onClear(e);
    }
    this.displayClear(e);
  }

  onBlur(e) {
    this.validateInput(e);
    if (this.props.onBlur) this.props.onBlur(e);
  }

  handleKeyUp(e) {
    if (this.props.type === "tel") {
      e.currentTarget.value = formatPhoneNumber(e.currentTarget.value);
    }
    if (this.props.onKeyUp) this.props.onKeyUp(e);
    this.displayClear(e);
  }

  handleFocus(e) {
    if (this.props.type === "tel") {
      e.currentTarget.value = formatPhoneNumber(e.currentTarget.value);
    }
    if (this.props.onFocus) this.props.onFocus(e);
    this.displayClear(e);
  }

  displayClear(e) {
    var clearBtn = document.getElementById(this.props.id + "-clear-btn");
    if (e.currentTarget.value.length === 0) clearBtn?.classList?.add("hide");
    else if (e.currentTarget.value.length > 0)
      clearBtn?.classList?.remove("hide");
  }

  isValidDateFormat(date) {
    // Note: Assumes all usages of the RDS date input will have the same min and max treatment
    let min = new Date(getDateInputISO(formInputValidations.scheduledDate.min));
    let max = new Date(getDateInputISO(formInputValidations.scheduledDate.max));
    let inputDate = new Date(date);

    // Normalize JS Date object to be correct current time
    if (!isNaN(inputDate.getTime())) {
      inputDate = new Date(
        inputDate.getTime() - inputDate.getTimezoneOffset() * -60000
      );
    }
    if (!isNaN(inputDate) && inputDate > min && max > inputDate) return true;
    return false;
  }

  validateInput(e) {
    var elm = e.currentTarget;
    var value = elm.value;
    var validationText = document.getElementById(
      this.props.id + "-validation-text"
    );
    const clearInputError = () => {
      validationText.innerHTML = "";
      validationText?.classList?.remove("error-text");
      elm.closest(".rds-input-text")?.classList?.remove("error");
    };
    const setInputError = (errorMessage = "") => {
      const icon = `<i class="fa fa-exclamation-circle me-2" aria-hidden="true"></i>`;
      validationText.innerHTML = errorMessage?.length
        ? `${icon}${errorMessage}`
        : `${icon}${this.props.errorHelperText}`;
      validationText?.classList?.add("error-text");
      elm.closest(".rds-input-text")?.classList?.add("error");
    };
    if (this.props.type === "email") {
      const reEmail = formInputValidations.emailAddress.pattern;
      if (!value.match(reEmail)) {
        setInputError();
      } else {
        clearInputError();
      }
    }
    if (this.props.type === "date") {
      if (this.isValidDateFormat(value)) {
        clearInputError();
      } else {
        setInputError("Please enter a valid Date");
      }
    } else if (this.props.type === "tel" && !this.props.isOptional) {
      const rePhone = formInputValidations.phoneNumber.pattern;
      if (!value.match(rePhone)) {
        setInputError();
      } else {
        clearInputError();
      }
    } else if (this.props.type === "text" && this.props.required) {
      if (!value) {
        setInputError();
      } else if (this.props.id === "zipCode") {
        const match = value?.match(formInputValidations.zipCode.pattern);
        if (!match) {
          setInputError("Enter a valid 5-digit zip code");
        } else {
          clearInputError();
        }
      } else {
        clearInputError();
      }
    }
  }

  render() {
    let { onBlur, onChange, value, ...props } = this.props;

    return (
      <div
        key={this.props.key}
        className={
          this.props.containerClass +
          (this.props.required && this.props.errorText ? " error" : "")
        }
      >
        {this.props.type === "textarea" ? (
          <textarea
            rows={this.props.rows}
            type={this.props.type}
            className={
              this.props.className + (this.props.mask ? " gbmask" : "")
            }
            name={this.props.name}
            aria-label={this.props.ariaLabel}
            id={this.props.id}
            key={this.props.defaultValue}
            text={this.props.text}
            placeholder={this.props.placeholder}
            disabled={this.props.disabled}
            onBlur={(e) => this.onBlur(e)}
            onKeyUp={(e) => this.handleKeyUp(e)}
            onKeyPress={this.props.onKeyPress}
            onKeyDown={this.props.onKeyDown}
            maxLength={formInputValidations[this.props.name]?.maxLength}
            pattern={this.props.pattern}
            inputMode={this.props.inputMode}
            required={this.props.required}
            value={this.props.value}
            onChange={this.props.onChange}
            onInput={this.props.onInput}
            onFocus={(e) => this.handleFocus(e)}
            autoComplete={this.props.autoComplete}
            ref={this.props.forwardedRef}
            defaultValue={this.props.defaultValue}
            {...this.props.dataAttributes}
            style={{ width: "100%" }}
          />
        ) : (
          <div>
            <input
              type={this.props.type}
              className={
                this.props.className + (this.props.mask ? " gbmask" : "")
              }
              name={this.props.name}
              aria-label={this.props.ariaLabel}
              id={this.props.id}
              key={this.props.defaultValue}
              text={this.props.text}
              placeholder={this.props.placeholder}
              disabled={this.props.disabled}
              onBlur={(e) => this.onBlur(e)}
              onKeyUp={(e) => this.handleKeyUp(e)}
              onKeyPress={this.props.onKeyPress}
              onKeyDown={this.props.onKeyDown}
              maxLength={formInputValidations[this.props.name]?.maxLength}
              minLength={this.props.minLength}
              pattern={this.props.pattern}
              inputMode={this.props.inputMode}
              required={this.props.required}
              value={this.props.value}
              onChange={this.props.onChange}
              onInput={this.props.onInput}
              onFocus={(e) => this.handleFocus(e)}
              autoComplete={this.props.autoComplete}
              ref={this.props.forwardedRef}
              defaultValue={this.props.defaultValue}
              min={this.props.min}
              max={this.props.max}
              {...this.props.dataAttributes}
            />
            <div className="label-wrap" id={this.props.id + "-label-wrap"}>
              <label
                htmlFor={this.props.id}
                data-content={
                  this.props.required
                    ? `${this.props.placeholder} *`
                    : this.props.placeholder
                }
                tabIndex="-1"
              >
                <span className="hidden-label">
                  {this.props.required
                    ? `${this.props.placeholder} *`
                    : this.props.placeholder}
                </span>
              </label>
            </div>
            <button
              tabIndex="-1"
              aria-label="clear"
              className="hide"
              id={this.props.id + "-clear-btn"}
              onMouseDown={(e) => this.clearInput(e)}
              onTouchStart={(e) => this.clearInput(e)}
            >
              <i
                className="fa-icon fa-regular fa-xmark"
                aria-label="clear"
                style={{ padding: "0px" }}
              ></i>
            </button>
            {this.props.required && (
              <div
                ref={this.props.validationRef}
                id={this.props.id + "-validation-text"}
                className="rds-input-validation-text error-text"
                {...this.props.dataValidationAttributes}
              >
                {this.props.errorText}
              </div>
            )}
            <div
              ref={this.props.helperRef}
              id={this.props.id + "-helper-text"}
              className="rds-input-helper-text"
            >
              {this.props.helperText}
            </div>

            {this.props.dateFormatLabel && (
              <span className="date-format-label rds-subtitle-2">
                MM/DD/YYYY
              </span>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default RdsTextInput;
