import React from "react";
import { Link } from "react-router-dom";
import styles from "../stylesheets/rds-seo-city-tray.module.css";

export default function SeoCityTray(props) {
  const {
    anchorTagValue = "",
    heading,
    imageUrl,
    imageAltText,
    gmbMapUrl: gmbMapUrl,
    sectionHeading,
    seoCityList = [],
    subHeading,
  } = props;

  const generateCityArrays = (cityList, subSectionQty) => {
    const cityCount = cityList?.length; // split city list into smaller arrays based on subsection quantity
    const iterator = Math.ceil(cityCount / subSectionQty);

    const cityArrays = [];
    for (let i = 0; i < subSectionQty; i++) {
      const startIndex = i * iterator;
      const endIndex = startIndex + iterator;
      const subsectionArray = cityList.slice(startIndex, endIndex);
      cityArrays.push(subsectionArray);
    }
    return cityArrays;
  };

  const generateCityTrayRenderableSubsectionsRegular = (cityArrays) => {
    const bootStrapClassMapper = {
      4: "col-12 col-sm-6 col-lg-3",
      3: "col-md-4",
      2: "col-6",
    };

    const bootStrapClasses =
      bootStrapClassMapper[cityArrays.length] ||
      "col-12 col-sm-6 col-md-4 col-lg-3";
    if (!bootStrapClassMapper[cityArrays.length]) {
      console.warn("Number of tray subsections did not match expected values");
    }

    return (
      <div className="row">
        {cityArrays.map((cityArray, index) => (
          <div key={index} className={`${bootStrapClasses} mb-2`}>
            {cityArray.map((city, cityIndex) => (
              <div key={cityIndex} className="city-name-wrapper mb-2">
                {city.redirectUrl ? (
                  <Link
                    to={city.redirectUrl}
                    target="_self"
                    className={`rds-font-body-1 ${styles.cityName}`}
                  >
                    <b>{city.cityName}</b>
                  </Link>
                ) : (
                  <span className={`rds-font-body-1 ${styles.cityName}`}>
                    {city.cityName}
                  </span>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const generateCityTrayRenderableSubsectionsLarge = (cityArrays) => {
    const bootStrapClassMapper = { 6: "col-lg-2", 4: "col-md-3", 2: "col-6" };

    const bootStrapClass =
      bootStrapClassMapper[cityArrays.length] || "col-md-3";
    if (!bootStrapClassMapper[cityArrays.length]) {
      console.warn("number of tray subsections did not match expected values");
    }

    return (
      <div className="row">
        {cityArrays.map((cityArray, index) => (
          <div key={index} className={`${bootStrapClass} mb-2`}>
            {cityArray.map((city, cityIndex) => (
              <div key={cityIndex} className="city-name-wrapper  mb-2">
                {city.redirectUrl ? (
                  <Link
                    to={city.redirectUrl}
                    target="_self"
                    className={`rds-font-body-1 ${styles.cityName}`}
                  >
                    <b>{city.cityName}</b>
                  </Link>
                ) : (
                  <span className={`rds-font-body-1 ${styles.cityName}`}>
                    {city.cityName}
                  </span>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const renderMapOrPlaceHolderPhoto = () =>
    gmbMapUrl ? (
      <iframe
        className={styles?.visualMap}
        src={gmbMapUrl}
        frameBorder="0"
        title="Map of Service Area"
      />
    ) : (
      <img
        className={styles?.visualMapPlaceHolder}
        src={imageUrl}
        alt={imageAltText || sectionHeading}
        title={imageAltText || sectionHeading}
      />
    );

  const renderDesktopCitiesRegular = (cityList) => {
    const cityArrays = generateCityArrays(cityList, 4);
    return generateCityTrayRenderableSubsectionsRegular(cityArrays);
  };

  const renderTabletCitiesRegular = (cityList) => {
    const cityArrays = generateCityArrays(cityList, 3);
    return generateCityTrayRenderableSubsectionsRegular(cityArrays);
  };

  const renderMobileCitiesRegular = (cityList) => {
    const cityArrays = generateCityArrays(cityList, 2);
    return generateCityTrayRenderableSubsectionsRegular(cityArrays);
  };

  const renderDesktopCitiesLarge = (cityList) => {
    const cityArrays = generateCityArrays(cityList, 6);
    return generateCityTrayRenderableSubsectionsLarge(cityArrays);
  };

  const renderTabletCitiesLarge = (cityList) => {
    const cityArrays = generateCityArrays(cityList, 4);
    return generateCityTrayRenderableSubsectionsLarge(cityArrays);
  };

  const renderMobileCitiesLarge = (cityList) => {
    const cityArrays = generateCityArrays(cityList, 2);
    return generateCityTrayRenderableSubsectionsLarge(cityArrays);
  };

  const renderCityTrayRegular = (cityList) => {
    return (
      <>
        <div className="row">
          <div className="headerWrapper">
            <div
              className={`d-flex justify-content-center mb-2 rds-font-button ${styles.sectionHeading}`}
            >
              {sectionHeading}
            </div>
            <div
              className={`d-flex justify-content-center mb-4 ${styles.heading}`}
            >
              {heading}
            </div>
            <div
              className={`d-flex justify-content-center mb-4 rds-font-title-5`}
            >
              {subHeading}
            </div>
          </div>
          <div className="col-md-6 order-2 order-md-1">
            <div className="d-none d-lg-block p-3">
              {/* Render for large screens (desktop) */}
              {renderDesktopCitiesRegular(cityList)}
            </div>

            <div className="d-none d-md-block d-lg-none p-3">
              {/* Render for medium screens (tablet) */}
              {renderTabletCitiesRegular(cityList)}
            </div>

            <div className="d-block d-md-none p-3">
              {/* Render for small screens (mobile) */}
              {renderMobileCitiesRegular(cityList)}
            </div>
          </div>
          <div className="col-md-6 order-1 order-md-2">
            <div
              className={`d-flex justify-content-center mb-4 px-4 p-md-0 p-3 `}
            >
              {renderMapOrPlaceHolderPhoto(gmbMapUrl)}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderCityTrayLarge = (cityList) => {
    return (
      <>
        <div className="headerWrapper">
          <div
            className={`d-flex justify-content-center mb-2 rds-font-button ${styles.sectionHeading}`}
          >
            {sectionHeading}
          </div>
          <div
            className={`d-flex justify-content-center mb-4 ${styles.heading}`}
          >
            {heading}
          </div>
          <div
            className={`d-flex justify-content-center mb-4 rds-font-title-5`}
          >
            {subHeading}
          </div>
        </div>

        <div className={`d-flex justify-content-center mb-4 px-4 p-md-0 p-3 `}>
          {renderMapOrPlaceHolderPhoto(gmbMapUrl)}
        </div>

        <div className="d-none d-lg-block p-3">
          {/* Render for large screens (desktop) */}
          {renderDesktopCitiesLarge(cityList)}
        </div>

        <div className="d-none d-md-block d-lg-none p-3">
          {/* Render for medium screens (tablet) */}
          {renderTabletCitiesLarge(cityList)}
        </div>

        <div className="d-block d-md-none p-3">
          {/* Render for small screens (mobile) */}
          {renderMobileCitiesLarge(cityList)}
        </div>
      </>
    );
  };

  return (
    <div className={`rds-wrapper pt-1 pt-md-3 px-3 px-md-0`} id={anchorTagValue}>
      <div className={`container mt-4`} >
        {seoCityList.length < 48
          ? renderCityTrayRegular(seoCityList)
          : renderCityTrayLarge(seoCityList)}
      </div>
    </div>
  );
}
