import React from "react";

import CircleWithNumber from "../../../Components/CircleWithNumber/CircleWithNumber";
import styles from "./StepsCard.module.css";

const StepsCard = ({ cardData = {} }) => {
  return (
    <div className={`${styles.stepsCardContainer} mb-3`}>
      <CircleWithNumber text={cardData?.step} />
      <h3 className="mt-4 text-center">{cardData?.cardTitle}</h3>
      <p className="text-center fs-6 mt-2">{cardData?.cardDesc}</p>
    </div>
  );
};

export default StepsCard;
