import React from "react";
import DynamicTrays from "../../Components/DynamicTrays";
import { useLocation } from "react-router-dom";

const FormSubmissionSuccessPage = () => {
  const location = useLocation();
  if (Object.keys(location.state.data)?.length) {
    return <DynamicTrays {...location.state.data} />;
  }
};

export default FormSubmissionSuccessPage;
