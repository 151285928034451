import React from "react";
import PropTypes from "prop-types";
import styles from "../stylesheets/rds-filter-list.module.css";
import "../stylesheets/rds-bootstrap.css";

const RDSFilterList = (props) => {
    const {
        selectedFilterList,
        onClickSelectedFilter,
        onClickClearAll,
    } = props;


    return (
        <div className="wrapper pb-3">
            <ul className={styles.filtersContainer}>
                {selectedFilterList?.map((filter) => (
                    <div className={`${styles.filters} px-2 py-1 `}
                        key={filter}
                        value={filter}
                    >
                        <div className={`${styles.filterText} `}>{filter}</div>
                        <div className={`${styles.closeIcon} `} onClick={() => onClickSelectedFilter(filter)}>&times;</div>
                    </div>
                ))}
                {selectedFilterList?.length ? <li className={styles.clearAll} onClick={() => onClickClearAll()}>Clear All</li> : null}
            </ul>
        </div>
    );
};

RDSFilterList.propTypes = {
    selectedFilterList: PropTypes.array,
    onClickClearAll: PropTypes.func,
    onClickSelectedFilter: PropTypes.func,
};

export default RDSFilterList;