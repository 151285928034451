import React, { Children, useState, useEffect }  from 'react';
import "../stylesheets/rds-accordion.css";

const Body = () => null;
Body.displayName = "Body";

function findSubcomponentByType(children, type) {
    var result = "";

    Children.forEach(children, child => {
        const childType = child && child.type && (child.type.displayName || child.type.name);
        
        if(type === childType) {
            result = child;
        }
    });

    return result;
};

function RdsAccordion(props) {
    const [open, setOpen] = useState(false);
    const [stickyHeader, setStickyHeader] = useState(false);
    const [pageHeaderHeight, setPageHeaderHeight] = useState(0);

    useEffect(() => {
        var accordionBody = document.getElementById("rds-accordion-body-" + props.id);
        accordionBody.style.height = accordionBody.offsetHeight+"px";
        accordionBody.classList.toggle("no-height");

        getPageHeaderHeight();
    }, []);

    function getPageHeaderHeight() {
        let _pageHeaderHeight = 0;
        if(document.querySelector("header.mz-pageheader")){
            _pageHeaderHeight = document.querySelector("header.mz-pageheader").scrollHeight;
        }else if(document.querySelector(".pageheader-wrapper")){
            _pageHeaderHeight = document.querySelector('.pageheader-wrapper').scrollHeight;
        }
        setPageHeaderHeight(_pageHeaderHeight);
        return _pageHeaderHeight;
    }

    const [scrollEventListener] = useState(() => () =>{
        let elm = document.getElementById(props.id);
        let offset = elm.getBoundingClientRect();
        let _pageHeaderHeight = getPageHeaderHeight();
        if(offset.top <= _pageHeaderHeight){
            setStickyHeader(true);
        }else { 
            setStickyHeader(false);
        }
    });
    
    function updateOpen(isOpen){
        document.getElementById("rds-accordion-body-" + props.id).classList.toggle("no-height");
        if(isOpen){
            window.addEventListener('scroll', scrollEventListener);
        }else{
            window.removeEventListener('scroll', scrollEventListener);
            if(stickyHeader){
                setTimeout(() => {
                    document.getElementById("rds-accordion-title-" + props.id).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
                }, "10");
            }
        }

        setOpen(isOpen);
        if(props.setOpen){
            props.setOpen(isOpen);
        }
    }

    function renderBody() {
        const { children } = props;
        const subcomponent = findSubcomponentByType(children, Body.displayName);
        
        if (!subcomponent) {
            return null;
        }
        return (<>{subcomponent.props.children}</>);
    }

    function onKeyDown(e){
        if(e.keyCode === 13 || e.keyCode === 32){
            updateOpen(!open);
        }
    }

    return (
    <div className={"rds-accordion " + (open ? "open" : "closed")} id={props.id} key={props.id}>
       <div className={"rds-accordion-title " + (stickyHeader ? "sticky" : "")} style={{ top : stickyHeader ? pageHeaderHeight : "unset" }} id={"rds-accordion-title-"+props.id} onClick={(e)=>updateOpen(!open)} tabIndex="0" onKeyDown={(e) => onKeyDown(e)}>
            <div className='rds-accordion-title-text'>{props.title}</div>
            <i className={"fa-icon " + (open ? "fa-solid fa-angle-up" : "fa-solid fa-angle-down")}></i>
       </div>
       <div className='rds-accordion-body' id={"rds-accordion-body-" + props.id} style={props.maxBodyWidth ? {maxWidth: props.maxBodyWidth} : {}}>
            <div className={'rds-accordion-inner-body ' + (props.hideBodyPadding ? "no-padding" : "")}>{renderBody()}</div>
       </div>
    </div>);
}

RdsAccordion.Body = Body;

export default RdsAccordion;
