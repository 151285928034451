import React from "react";
import PropTypes from "prop-types";
import "../stylesheets/rds-common.css";
import "../stylesheets/rds-hero-banner.css";
import ExternalSystemMessageTray from "./rds-external-system-message";
import Rdsbtn from "./rds-btn";

const RdsHeroBanner = ({
  bannerImage,
  bannerLogo,
  title,
  subTitle,
  description,
  disclaimer,
  externalFranchiseMessage,
  cta,
  franchiseData,
}) => {
  const linkButtonProps = {
    text: cta?.text,
    buttonClass: "rds rds-btn primary",
    iconClass: "fa-icon leading fa-regular fa-magnifying-glass",
    iconType: "leading",
    disabled: false,
  };

  const handleClick = () => {
    if (cta.type === "Link" && cta.openInNewTab) {
      window.open(cta.value, "_blank");
    }
    if (cta.type === "Link" && !cta.openInNewTab) {
      window.open(cta.value, "_self");
    }
  };
  const headerFranchisePhone =
    franchiseData?.franchiseDetails?.headerFranchisePhone;

  const renderRdsBtn = !headerFranchisePhone ? null : (
    <Rdsbtn
      tel={headerFranchisePhone}
      text={cta?.text}
      buttonClass="rds rds-btn primary"
      iconClass={"fa-icon leading fa-regular fa-magnifying-glass"}
      iconType={"leading"}
      disabled={false}
      franchiseDetails={franchiseData?.franchiseDetails}
    />
  );

  return (
    <>
      {externalFranchiseMessage && (
        <ExternalSystemMessageTray
          externalFranchiseMessage={externalFranchiseMessage}
        />
      )}
      <div
        className="hero-banner-tray"
        style={{ backgroundImage: `url(${bannerImage})` }}
      >
        <div className="hero-banner-background">
          <div className="container">
            <div className="row hero-banner-row">
              <div className="col align-self-center col-12 py-5 px-2 col-md-6 pe-0 pe-md-5 content">
                {(!cta || (cta && !headerFranchisePhone)) && bannerLogo && (
                  <img src={bannerLogo} alt="AceLogo" className="banner-logo" />
                )}
                <h1 className="rds-font-title-1">{title}</h1>
                {subTitle && <div className="subtitle">{subTitle}</div>}
                {description && <p className="rds-font-product-title-2">{description}</p>}
                {disclaimer && <p className="rds-font-caption">{disclaimer}</p>}

                {cta && cta?.text && cta.type === "Link" && (
                  <Rdsbtn {...linkButtonProps} onClick={handleClick} />
                )}

                {cta && cta?.text && cta.type === "Phone_Number" && renderRdsBtn}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

RdsHeroBanner.propTypes = {
  bannerImage: PropTypes.string,
  bannerLogo: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
};

export default RdsHeroBanner;
