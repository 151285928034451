import React from "react";
import PropTypes from "prop-types";
import "../stylesheets/rds-icon-tray.css";
import { getTrayStyle } from '../utility';

const IconTray = ({ heading, subHeading, backgroundColor = "white", icons = [], anchorTagValue }) => {
  const trayStyle = getTrayStyle(backgroundColor);

  return (
    <div className="rds-wrapper" style={trayStyle} id={anchorTagValue}>
      <div className="container">
        {heading && <h2 className="rds-section-heading">{heading}</h2>}
        {subHeading && <h3 className="rds-heading">{subHeading}</h3>}
        <div className={`icon-tray ${icons.length <= 4 ? 'center-align' : ''}`}>
          {icons.map((icon, index) => (
            <div key={index} className="icon-container">
              {icon.link ? (
                <a href={icon.link} target="_blank" rel="noopener noreferrer">
                  <img
                    src={icon.image}
                    alt={icon?.imageAltText || icon.heading}
                    title={icon?.imageAltText || icon.heading}
                    className="icon-image"
                  />
                </a>
              ) : (
                <img
                  src={icon.image}
                  alt={icon?.imageAltText || icon.heading}
                  title={icon?.imageAltText || icon.heading}
                  className="icon-image"
                />
              )}
              <p className="icon-heading">{icon.heading}</p>
              <p className="icon-description">{icon.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

IconTray.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  backgroundColor: PropTypes.string,
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      heading: PropTypes.string.isRequired,
      link: PropTypes.string,
      altImageText: PropTypes.string,
      description: PropTypes.string
    })
  ).isRequired,
  anchorTagValue: PropTypes.string
};


export default IconTray;
