import React from "react";
import { Link } from "react-router-dom";
import AceLogo from "../../../assets/footer/Ace-Red-Logo.svg";
import styles from "./rds-businesslogos.module.css";

const BusinessLogos = ({ businessLogoItems = [] }) => {
  return (
    <ul className={styles.logoContainer}>
      {businessLogoItems.map((logo, index) => {
        return (
          <li key={index}>
            <Link
              to={logo.url}
              target={logo.target ? logo.target : "_self"}
              rel={`${logo.target ? "noopener noreferrer" : ""}`}
            >
              <img src={AceLogo} alt={logo.alt} width="174px" height="64px" />
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default BusinessLogos;
