import React from 'react';
import { Link } from 'react-router-dom';

const Logo = (props) => {
    const { url, alt, path, imgRatio = {}, imageClass = "" } = props;
    const { width, height } = imgRatio;
    return (
        <Link
            className={`navbar-brand p-0`}
            to={url}
        >
            <img
                src={path}
                alt={alt}
                className={imageClass}
                width={width}
                height={height}
            />
        </Link>
    )
}

export default Logo
