import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../stylesheets/rds-common.css";
import styles from "../stylesheets/rds-category.module.css";
import "../stylesheets/rds-bootstrap.css";

const RdsCategory = ({
  label,
  isSelected,
  handleClick,
  size = "default",
  type = "category"
}) => {
  const [selected, setSelected] = useState(false);

  const sizeMap = {
    "default": "rds-font-product-title-1",
    "small": "rds-font-subtitle-2",
  };

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  const handleOnClick = (value) => {
    handleClick?.(value);
  };

  return (
    <div
      className={`${styles.wrapper} ${styles[type]} ${selected ? `${styles.selected}` : ""}`}
      onClick={() => handleOnClick(label)}
    >
      <span className={`${styles.label} ${sizeMap[size]}`}>{label}</span>
    </div>
  );
};

RdsCategory.propTypes = {
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  handleOnClick: PropTypes.func,
  size: PropTypes.string,
  type: PropTypes.string
};

export default RdsCategory;
