import React, { Component } from 'react';
import '../stylesheets/rds-booking-journey-tray.css';
import { getTextStyle, getTrayStyle } from '../utility';

class RdsBookingJourneyTray extends Component {
  render() {
    const { desktopImageUrl, mobileImageUrl, isMobile, sectionHeading, heading, backgroundColor, anchorTagValue, imageAltText } = this.props;
    const trayStyle = getTrayStyle(backgroundColor);
    const textStyle = getTextStyle(backgroundColor);


    return (
      <div className="rds-wrapper" style={trayStyle} id={anchorTagValue}>
        <div className="container">
          <div className="row justify-content-center">
            <h2 className="rds-section-heading" style={textStyle}>{sectionHeading}</h2>
            <h3 className="rds-heading mb-4" style={textStyle}>{heading}</h3>
            <div>
              <img
                src={isMobile ? mobileImageUrl : desktopImageUrl}
                alt={imageAltText? imageAltText:sectionHeading}
                title={imageAltText? imageAltText:sectionHeading}
                width={'100%'}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RdsBookingJourneyTray;
