import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import GoogleTagManager from "./GoogleTagManager";
import FranchiseHome from "../pages/franchiseHome";
import FranchiseMaintenance from "../pages/franchiseMaintenance";
import FranchiseOurCompany from "../pages/franchiseOurCompany";
import FranchiseContactUs from "../pages/franchiseContactUs";
import FranchiseBookingService from "../pages/franchiseBookingService";
import FranchiseSEOCity from "../pages/franchiseSEOCity";

const TargetPageWithGTM = ({ name }) => {
  const { franchiseName } = useParams();
  const gtmHeaderId = useSelector(
    (state) =>
      state.franchiseHeader?.data?.franchiseDetails
        ?.franchiseHeaderGTagScript ?? "default_value"
  );
  const gtmFooterId = useSelector(
    (state) =>
      state.franchiseHeader?.data?.franchiseDetails?.franchiseBodyGTagScript ??
      "default_value"
  );

  let franchiseComponent = null;

  franchiseComponent = (
    <>
      {(gtmHeaderId !== "default_value" || gtmFooterId !== "default_value") && (
        <GoogleTagManager gtmHeaderId={gtmHeaderId} gtmFooterId={gtmFooterId} />
      )}
      {name === "FranchiseHome" && (
        <FranchiseHome franchiseName={franchiseName} />
      )}
      {name === "FranchiseMaintenance" && (
        <FranchiseMaintenance franchiseName={franchiseName} />
      )}
      {name === "FranchiseOurCompany" && (
        <FranchiseOurCompany franchiseName={franchiseName} />
      )}
      {name === "FranchiseContactUs" && (
        <FranchiseContactUs franchiseName={franchiseName} />
      )}
      {name === "FranchiseSEOCity" && (
        <FranchiseSEOCity franchiseName={franchiseName} />
      )}
      {name === "FranchiseBookingService" && (
        <FranchiseBookingService franchiseName={franchiseName} />
      )}
    </>
  );

  return franchiseComponent;
};

export default TargetPageWithGTM;
